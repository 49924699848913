import React from "react";
import {BackendLayout} from "./../../../../layouts/backend.layout";

import {EditEducationComponent} from "../../../../components/backend/education/edit.education.component";

export class EditEducationPage extends React.Component {




    render() {
        return (<>
            <BackendLayout>
                <EditEducationComponent
                    title={'Edit Education'}
                    breadcumb={['Resume','Education','Edit']}
                    btnTitle={'List Education'}
                    btnTo={'/admin/education'}
                    routeProps={this.props}>

                </EditEducationComponent>
            </BackendLayout>
        </>)
    }
}
