import React from 'react';
import './contact.dark.component.css';
import myHttp from "../../../util/http";
import notify from "../../../util/notify";
import Loader from 'components/common/loader/loader';
// import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import Aos from "aos";
import "aos/dist/aos.css";


const defaultForm = {
    name: '',
    email: '',
    subject: '',
    message: ''
}

export class ContactDarkComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            contact: [],
            socials: [],
            isSubmitting: false,
            isValidForm: false,
            data: {
                ...defaultForm
            },
            error: {
                ...defaultForm
            },
            pure: {
                name: true,
                email: true,
                subject: true,
                message: true
            }
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState(preState => ({
            data: {
                ...preState.data,
                [name]: value
            },
            pure: {
                ...preState.pure,
                [name]: false
            }
        }), () => {
            this.validateForm(name);
        });

    }


    validateForm(fieldName) {

        let errMsg;
        switch (fieldName) {
            case 'name':
                errMsg = this.state.data[fieldName]
                    ? this.state.data[fieldName].length > 2
                        ? ''
                        : 'Name must be 3 characters in length'
                    : 'Name is required'
                break;


            case 'subject':
                errMsg = this.state.data[fieldName]
                    ? this.state.data[fieldName].length > 2
                        ? ''
                        : 'Subject must be 3 characters in length'
                    : 'Subject is required'
                break;

            case 'message':
                errMsg = this.state.data[fieldName]
                    ? this.state.data[fieldName].length > 5
                        ? ''
                        : 'Message must be 6 characters in length'
                    : 'Message is required'
                break;


            case 'email':
                errMsg = this.state.data[fieldName]
                    ? this.state.data[fieldName].includes('@') && this.state.data[fieldName].includes('.com')
                        ? ''
                        : 'Invalid Email'
                    : 'Email field is required'
                break;

            default:
                break;
        }
        this.setState(preState => ({
            error: {
                ...preState.error,
                [fieldName]: errMsg
            }
        }), () => {
            const { error, pure } = this.state;
            let err = Object.values(error).filter(item => item);
            let dirty = Object.values(pure).filter(item => item);
            // console.log(err);
            this.setState({
                isValidForm: err.length === 0 && dirty.length === 0
            })
        })
    }

    componentDidMount() {
        Aos.init({
            duration: 1200,
        })
        // this.setState({
        //     isLoading: true
        // })
        myHttp.GET('/contact')
            .then(res => {
                this.setState({
                    contact: res.data[0]
                })
            })
            .catch(err => {
                // notify.handleError(err)
            })


        myHttp.GET('/social/active')
            .then(res => {
                this.setState({
                    socials: res.data
                })
            })
            .catch(err => {
                // console.log(err);
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    handleSubmit = e => {

        e.preventDefault();
        this.setState({
            isSubmitting: true
        });

        myHttp.POST('/contact/sendEmail', this.state.data)
            .then(res => {

                notify.showSuccess('Thank you ' + this.state.data.name.toUpperCase() + '. Email Sent Successfully.');
                this.setState(prev => ({
                    isSubmitting: false,
                    data: {
                        email: '',
                        name: '',
                        subject: '',
                        message: ''
                    },
                    error: {
                        email: '',
                        name: '',
                        subject: '',
                        message: ''
                    },
                    isValidForm: false,
                    pure: {
                        email: true,
                        name: true,
                        message: true,
                        subject: true,
                    },
                }),
                    this.props.history.push('/contact')
                )
            })
            .catch(err => {
                // console.log(err);
                // notify.handleError(err);
                this.setState(prev => ({
                    isSubmitting: false,
                    data: {
                        ...prev.data,
                        email: '',
                        name: '',
                        subject: '',
                        message: ''
                    },
                    error: {
                        ...prev.error,
                        email: '',
                        name: '',
                        subject: '',
                        message: ''
                    },
                    isValidForm: false,
                    pure: {
                        email: true,
                        name: true,
                        message: true,
                        subject: true,
                    },
                }))
            })
            .finally(
                this.setState({
                    isSendingEmail: false
                })
            )
    }

    render() {

        let btn = this.state.isSubmitting
            ? <div className="text-center">
                <div className="loading">Sending your email...Please wait a moment!</div>
            </div>
            : this.state.isValidForm
                ? <div className="text-center">
                    <button className="btn btn-success" type="submit">Send Message</button>
                </div>
                : <div className="text-center">
                    <button disabled className="btn btn-danger" type="submit">Send Message</button>
                </div>

        let content = this.state.isLoading
            ? <Loader />
            : <>
                <div className="container">

                    <div className="section-title">
                        <h2>Contact</h2>
                        <p>Contact Me</p>
                    </div>

                    <div className="row mt-2">
{console.log(this.state.contact)}
                        {
                            
                            this.state.socials.length > 0 && this.state.contact.social_profile == 'Active'
                                ? <>
                                    <div className="col-md-6 d-flex align-items-stretch">
                                        <div data-aos="flip-right" className="info-box">
                                            <i className="bx bx-map"></i>
                                            <h3>My Address</h3>
                                            <p>{
                                                this.state.contact
                                                    ? this.state.contact.address
                                                    : ''
                                            }</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6 mt-4 mt-md-0 d-flex align-items-stretch">
                                        <div data-aos="flip-left" className="info-box">
                                            <i className="bx bx-share-alt"></i>
                                            <h3>Social Profiles</h3>
                                            <div className="social-links">
                                                {
                                                    this.state.socials.map(
                                                        (social, index) => (
                                                            <a rel="noreferrer" key={`social_` + index} target="_blank"
                                                                title={social.name}
                                                                href={social.url} className="twitter"><i
                                                                    className={social.logo}></i></a>
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : <div className="col-md-12 d-flex align-items-stretch">
                                <div data-aos="flip-right" className="info-box">
                                    <i className="bx bx-map"></i>
                                    <h3>My Address</h3>
                                    <p>{
                                        this.state.contact
                                            ? this.state.contact.address
                                            : ''
                                    }</p>
                                </div>
                            </div>
                        }


                        <div className="col-md-6 mt-4 d-flex align-items-stretch">
                            <div data-aos="flip-up" className="info-box">
                                <i className="bx bx-envelope"></i>
                                <h3>Email Me</h3>
                                <p>{this.state.contact ? this.state.contact.email_display : null}</p>
                            </div>
                        </div>
                        <div data-aos="flip-down" className="col-md-6 mt-4 d-flex align-items-stretch">
                            <div className="info-box">
                                <i className="bx bx-phone-call"></i>
                                <h3>Call Me</h3>
                                <p>{this.state.contact ? this.state.contact.mobile : null}</p>
                            </div>
                        </div>
                    </div>

                    <form data-aos="zoom-in" onSubmit={this.handleSubmit} method="post" role="form"
                        className="php-email-form mt-4">
                        <div className="row">
                            <div className="col-md-6">
                                <input type="text" name="name" className="form-control" id="name"
                                    onChange={this.handleChange}
                                    value={this.state.data.name}
                                    placeholder="Your Name" required />
                                <p className="error">{this.state.error.name}</p>

                            </div>
                            <div className="col-md-6 mt-md-0">
                                <input type="email" className="form-control" name="email" id="email"
                                    onChange={this.handleChange}
                                    value={this.state.data.email}
                                    placeholder="Your Email" required />
                                <p className="error">{this.state.error.email}</p>

                            </div>
                        </div>
                        <div className="form-group mt-3">
                            <input type="text" className="form-control" name="subject" id="subject"
                                onChange={this.handleChange}
                                value={this.state.data.subject}
                                placeholder="Subject" required />
                            <p className="error">{this.state.error.subject}</p>

                        </div>
                        <div className="form-group mt-3">
                            <textarea className="form-control" name="message" rows="5" placeholder="Message"
                                onChange={this.handleChange}
                                value={this.state.data.message}
                                required></textarea>
                            <p className="error">{this.state.error.message}</p>

                        </div>

                        {btn}
                    </form>


                    {
                        this.state.contact.map
                            ? <div
                                dangerouslySetInnerHTML={{
                                    __html: this.state.contact.map
                                }}></div>
                            : null
                    }



                </div>
            </>


        return (
            <>
                {/*-- ======= Contact Section ======= --*/}
                <section id="contact" className="contact section-show">
                    {content}

                </section>
            </>
        )
    }
}