import {Link,withRouter} from 'react-router-dom';
import './header.component.css';

const logout = (history) => {

    localStorage.clear();

    history.push('/');
}

const HeaderComponent = (props) => {
    return (<>

        <nav className="bnavbar navbar-icon-top navbar-expand-lg navbar-dark bg-dark">

            <button className="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                        <Link className="nav-link" to="/">

                            <u>Personal WebPage - Portfolio of Sujit Dahal</u>
                            <span className="sr-only">(current)</span>
                        </Link>
                    </li>


                </ul>
                <ul className="navbar-nav ">

                    <li className="nav-item">
                        <a onClick={()=>logout(props.history)} className="nav-link" href="/">
                            <i className="fa fa-sign-out">

                            </i>
                            Sign Out
                        </a>
                    </li>
                </ul>

            </div>
        </nav>

    </>)
}

export  default withRouter(HeaderComponent);
