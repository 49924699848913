import React from 'react';
import './stat.dark.component.css';
import myHttp from "../../../../util/http";
// import {fadeInLeft,flash,flip,shake,tada,swing,rubberBand} from 'react-animations';
import { StyleRoot } from 'radium';
import { TinyLoader } from 'components/UI/TinyLoader';


// const styles = {
//     flash: {
//         animation: 'x 1s',
//         animationName: Radium.keyframes(flash, 'flash')
//     },
//     fadeInLeft: {
//         animation: 'x 1s',
//         animationName: Radium.keyframes(flash, 'flash')
//     }
// }

export class StatDarkComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: [],
            isLoading: true
        }
    }

    componentDidMount() {
        myHttp.GET('/fact')
            .then(res => {
                this.setState({
                    stats: res.data,
                    isLoading: false
                })
            })
            .catch(err => {
                // console.log(err);
            })
    }


    render() {
        let content = this.state.isLoading
            ? <TinyLoader msg="Loading..." />
            : <div  data-aos="flip-right" className="row">

                {
                    this.state.stats.map(
                        (stat, index) => (
                            <div key={index} className="col-lg-3 col-md-6">
                                <div className="count-box">
                                    <i className={stat.logo}></i>
                                    <span data-purecounter-start="0" data-purecounter-end={stat.value}
                                        data-purecounter-duration="1" className="purecounter">{stat.value}</span>
                                    <p>{stat.name}</p>
                                </div>
                            </div>
                        )
                    )
                }


            </div>
        return (
            <>
                {/*!-- ======= Counts ======= --*/}
                <StyleRoot>
                    <div className="counts container">
                        
                            {content}
                        

                    </div>
                </StyleRoot>
            </>
        )
    }
}