import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
import {ListUserComponent} from "../../../components/backend/user/list.user.component";

export class ListUserPage extends React.Component{
    constructor(){
        super();

        this.state = {
            test:''
        }
    }

    render(){
        return(
            <>

                <BackendLayout>
                    <ListUserComponent
                        title="List User"
                        routeProps={this.props}
                        breadcumb={['User','List']}
                        btnTitle="Create User"
                        btnTo="/user/create">

                    </ListUserComponent>
                </BackendLayout>

            </>
        )
    }
}
