import React from 'react';
import './about.dark.component.css';
import myHttp from "../../../../util/http";

// import {fadeInLeft,flash,flip,shake,tada,swing,rubberBand} from 'react-animations';
import Radium, { StyleRoot } from 'radium';

import Loader from './../../../common/loader/loader';

import { TinyLoader } from 'components/UI/TinyLoader';


// const styles = {
//     flash: {
//         animation: 'x 1s',
//         animationName: Radium.keyframes(flash, 'flash')
//     },
//     fadeInLeft: {
//         animation: 'x 1s',
//         animationName: Radium.keyframes(flash, 'flash')
//     }
// }

const imgURL = process.env.REACT_APP_IMG_URL;

export class AboutDarkComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            aboutme: [],
            details: [],
            isLoading: true
        }
    }

    componentDidMount() {
        myHttp.GET('/about/active')
            .then(res => {
                this.setState({
                    aboutme: res.data[0],
                    details: res.data[0].details,
                    isLoading: false
                })
            })
            .catch(err => {
                // console.log(err);
            })
    }

    render() {
        let content = this.state.isLoading
            ? <div className="section-title">
                <h2>About</h2>
                <TinyLoader msg="Loading..." />
            </div>
            : <>
                <div className="section-title">
                    <h2>About</h2>
                    <p>{this.state.aboutme.page_intro}</p>
                </div>
                <div className="row">
                    <div className="col-lg-3" data-aos="fade-up">
                        <img src={`${imgURL}/${this.state.aboutme.image}`} className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
                        <h3>{this.state.aboutme.designation}</h3>
                        <p className="fst-italic">
                            {this.state.aboutme.designation_info}
                        </p>
                        <div className="row">
                            <div className="col-lg-6">
                                <ul>
                                    {
                                        this.state.details.filter(
                                            (item, index) => {
                                                if (index % 2 === 0) {
                                                    return true
                                                }
                                            }
                                        ).map((detail, index) => (

                                            <li key={'top' + index}><i className="bi bi-chevron-right"></i> <span>{detail}</span></li>

                                        )
                                        )
                                    }

                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <ul>
                                    {
                                        this.state.details.filter(
                                            (item, index) => {
                                                if (index % 2 === 1) {
                                                    return true
                                                }
                                            }
                                        ).map((detail, index) => (

                                            <li key={index}><i className="bi bi-chevron-right"></i> <span>{detail}</span></li>

                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <p style={{ textAlign: 'justify' }}>
                           
                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: this.state.aboutme.description
                                                }}></div>
                        </p>
                    </div>
                </div>
            </>
        return (
            <>

                {/*-- ======= About Me ======= --*/}
                <StyleRoot>
                    <div className="about-me container">


                        {content}

                    </div>
                </StyleRoot>
            </>
        )
    }
}