import React, {Component} from 'react';
// import {FrontendLayout} from "layouts/frontend.layout";
import {HeaderDarkComponent} from "../../components/header/frontend/dark/header.dark.component";
// import {FrontendDarkLayout} from "../../layouts/frontend.dark.layout";
import FooterBackend from "../../components/footer/backend/footer.backend";

import useDocumentTitle from './../../components/useDocumentTitle';



// export default class Home extends Component{


//     render() {
//         return (
//             <>

//                      {/*// -- ======= Header ======= -->*/}
//                      <HeaderDarkComponent/>

//                      <FooterBackend/>



//             </>
//         )
//     }
// }

export default function Home(){
    useDocumentTitle('🏠 Home 🏠 | Web Developer | Portfolio | Sujit Dahal 🧍')

        return (
            <>
                     {/*// -- ======= Header ======= -->*/}
                     <HeaderDarkComponent/>
                     <FooterBackend/>
            </>
        )
}