import React, {useEffect, useState} from "react";

import {Link} from 'react-router-dom';

// import Loader from 'components/common/loader/loader';
import {Formik, Form, Field, ErrorMessage} from 'formik'

import './form.testimonial.component.module.css';
import Input from "@material-ui/core/Input";

import {object, string, mixed} from 'yup';
import {Submitting} from "../../UI/Submitting";

const imgUrl = process.env.REACT_APP_IMG_URL;

// componentDidMount() {
//     if (this.props.testimonialX) {
//         this.setState({
//             data: {
//                 ...defaultForm,
//                 ...this.props.userx
//             }
//         }, () => {
//
//         })
//     }

function TestimonialForm(props) {

    const [formValues, setFormValues] = useState(null);
    const [editForm, setEditForm] = useState(false);
    const [isNewImage, setIsNewImage] = useState(false);

    const validate = object({
        name: string()
            .required('Name is required')
            .max(20, 'Must be 20 characters or less')
            .min(3, 'Must be 3 characters or more'),
        designation: string()
            .min('2', 'Must be 2 characters or more'),
        message: string()
            .required('Message is required')
            .min('2', 'Must be 2 characters or more')
            .max('350', 'Must be 350 characters or less'),
        status: mixed()
            .oneOf(['Active', 'Inactive'])
            .required('Status is required')

        // image: mixed()
        //     .required('Image is required')
        //     .test("size", "File is too large", (value) => {
        //         console.log('sizeee >> ', value);
        //         return value && value.size <= 1 * 1000 * 1000;
        //     })
        //     .test("type", "Supportive file formats required:.jpeg, .jpg, .png", (value) => {
        //         console.log('vallllllllllllllueee >> ', value)
        //
        //         return value && (
        //             value.type === "image/jpeg" ||
        //             value.type === "image/jpg"
        //         )
        //     })


    })


    const initialValues = {
        name: '',
        designation: '',
        status: '',
        message: '',
        image: '',
        preview: ''
    }

    // VALIDATE Only if New Image is Selected then goes for validation
   if(isNewImage){
       validate.fields.image = mixed()
           .required('Image is required')
           .test("size", "File is too large", (value) => {
               console.log('sizeee >> ', value);
               return value && value.size <= 1 * 1000 * 1000;
           })
           .test("type", "Supportive file formats required:.jpeg, .jpg, .png", (value) => {
               console.log('vallllllllllllllueee >> ', value)

               return value && (
                   value.type === "image/jpeg" ||
                   value.type === "image/jpg"
               )
           })
       validate._nodes.push('image');
   }




    useEffect(() => {
        if (props.testimonialX) {
            setFormValues(props.testimonialX);
            setEditForm(true);
        }

    })


    return (
        <>


            {/*let btn = props.isSubmitting*/}
            {/*? <button type="submit" disabled className="btn btn-secondary btn-lg btn-block">Submitting...</button>*/}
            {/*: <button type="submit" disabled={!this.state.isValidForm}*/}
            {/*className="btn btn-primary btn-lg btn-block">Submit</button>*/}

            {/*let content = this.props.isLoading*/}
            {/*? <>*/}
            {/*<Loader/>*/}
            {/*</>*/}
            {/*: <h1>form huncha</h1>*/}


            <div className="breadcrumb d-flex justify-content-between align-items-center">
                <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item"><Link to="#">{props.breadcumb[0]}</Link></li>
                    <li className="breadcrumb-item active">{props.breadcumb[1]}</li>
                </ol>
                <h4 className="text-center mytitle">{props.title}</h4>
                <Link to={props.btnTo} className="btn btn-sm btn-success">{props.btnTitle}</Link>
            </div>


            <Formik
                initialValues={formValues || initialValues}
                validationSchema={validate}
                enableReinitialize
                onSubmit={
                    (values, submitProps) => {
                        submitProps.setSubmitting(true);
                        props.submitCallback(values, null);
                        submitProps.resetForm(true);
                        submitProps.setSubmitting(false);
                    }
                }
            >
                {
                    (formikProps, setFieldValue, field) => (
                        <>
                            {/*{console.log('formik props >> ',formikProps)}*/}
                            {

                                     <Form noValidate>
                                        <div className="form-row">
                                            <div className="col-md-4 mb-12">
                                                <label className="label-color">Name*</label>
                                                <Field type="text"
                                                       className={formikProps.touched.name
                                                           ? formikProps.errors.name
                                                               ? 'form-control is-invalid'
                                                               : 'form-control is-valid'
                                                           : 'form-control'
                                                       }

                                                       id="validationServer01"
                                                       name="name"
                                                       placeholder="Full Name"
                                                       autoComplete="off"
                                                />
                                                <ErrorMessage name="name" component="div"
                                                              className="invalid-feedback"></ErrorMessage>
                                            </div>


                                            <div className="col-md-4 mb-12">
                                                <label htmlFor="validationServer02">Designation/Company</label>
                                                <Field type="text"
                                                       className={formikProps.touched.designation
                                                           ? formikProps.errors.designation
                                                               ? 'form-control is-invalid'
                                                               : 'form-control is-valid'
                                                           : 'form-control'
                                                       }
                                                       id="validationServer02"
                                                       placeholder="Designation/Company"
                                                       name="designation"
                                                       autoComplete="off"
                                                       value={formikProps.values.designation}
                                                />
                                                <ErrorMessage name="designation" component="div"
                                                              className="invalid-feedback"></ErrorMessage>

                                            </div>

                                            <div className="col-md-4 mb-12">
                                                <label htmlFor="validationServerUsername">Status*</label>
                                                <div className="form-group">
                                                    <Field as="select" name="status"
                                                           className={formikProps.touched.status
                                                               ? formikProps.errors.status
                                                                   ? 'form-control is-invalid'
                                                                   : 'form-control is-valid'
                                                               : 'form-control'
                                                           }
                                                           required>
                                                        <option value="">Select Status</option>
                                                        <option value="Active">Active</option>
                                                        <option value="Inactive">Inactive</option>
                                                    </Field>
                                                    <ErrorMessage name="status" component="div"
                                                                  className="invalid-feedback"></ErrorMessage>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="form-row">
                                            <div className="col-md-12 mb-12">
                                                <label htmlFor="validationServer03">Message*</label>
                                                <Field as="textarea" rows="5" type="text" name="message"
                                                       className={formikProps.touched.message
                                                           ? formikProps.errors.message
                                                               ? 'form-control is-invalid'
                                                               : 'form-control is-valid'
                                                           : 'form-control'
                                                       }
                                                       id="validationServer03"
                                                       placeholder="Feedback or Message from Client" required/>
                                                <ErrorMessage name="message" component="div"
                                                              className="invalid-feedback"></ErrorMessage>
                                            </div>

                                        </div>

                                        <div className="form-group">

                                            {

                                                formikProps.values.preview && isNewImage
                                                    ? <p>Preview&nbsp;
                                                        <img className="img-thumbnail"
                                                             src={formikProps.values.preview}
                                                             alt="Testimonial Pic" width="200px" height="150px"/>
                                                    </p>
                                                    : editForm && formikProps.values.preview
                                                    ? <p>Currently&nbsp;
                                                        <img className="img-thumbnail"
                                                             src={`${imgUrl}/${formikProps.values.preview}`}

                                                             alt="Testimonial Pic" width="200px" height="150px"/>
                                                    </p>
                                                    : ''
                                            }

                                            <div className="custom-file">
                                                <Input
                                                    onChange={(event) => {
                                                        setIsNewImage(true);
                                                        formikProps.setTouched({
                                                            image:true
                                                        })
                                                        if (event.target.files[0]) {
                                                            formikProps.setFieldValue("preview",
                                                                URL.createObjectURL(event.target.files[0]));
                                                            formikProps.setFieldValue("image", event.target.files[0]);
                                                        }
                                                    }}
                                                    type="file"
                                                    name="image"
                                                    className={formikProps.touched.image
                                                        ? formikProps.errors.image
                                                            ? 'custom-file-input is-invalid'
                                                            : 'custom-file-input is-valid'
                                                        : 'custom-file-input'
                                                    }
                                                    id="validatedCustomFile"/>
                                                <label className="custom-file-label" htmlFor="validatedCustomFile">
                                                    Choose Image file...</label>
                                                <ErrorMessage className="invalid-feedback" name="image" component="div"
                                                             ></ErrorMessage>

                                            </div>
                                        </div>
                                         {
                                             formikProps.isSubmitting
                                             ?<Submitting msg="Submitting... Please Wait!!!"/>
                                             // :<button className="btn btn-primary"
                                             :<button className="btn btn-primary btn-lg btn-block" disabled={formikProps.isValid && formikProps.dirty?false:true}
                                                      type="submit">Submit
                                                 </button>
                                         }

                                    </Form>
                            }


                        </>
                    )
                }
            </Formik>
            <br/>


        </>
    )
}

export {TestimonialForm}
