import React from 'react';
import './portfolio.dark.component.css';
import './details.dark.component.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import myHttp from "../../../util/http";
import Loader from "components/common/loader/loader";
import { ImageModal } from "../../UI/MyModal";
import { Link } from 'react-router-dom';
import { parse } from "dotenv";
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';

import Aos from "aos";
import "aos/dist/aos.css";



const imageURL = process.env.REACT_APP_IMG_URL;

export class DetailsDarkComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cats: [],
            portfolios: [],
            selectedCat: 'ALL',
            isLoading: true,
            isModalStatus: false,
            imgURL: '',

            imageArray: [],
            details: '',
            category: ''
        }
    }


    // fetchCategory = e => {
    //     myHttp.GET('/category/active')
    //         .then(res => {
    //             this.setState(pre => ({
    //                 ...pre,
    //                 cats: res.data
    //             }))
    //         })
    //         .catch(err => {
    //             // console.log(err);
    //         })
    //         .finally(
    //             this.fetchPortfolios
    //         )
    // }
    //
    // fetchPortfolios = e => {
    //     myHttp.GET('/portfolio/active')
    //         .then(res => {
    //             this.setState(pre => ({
    //                 ...pre,
    //                 portfolios: res.data
    //             }))
    //         })
    //         .catch(err => {
    //             // console.log(err);
    //         })
    //         .finally(
    //         )
    // }

    componentDidMount() {
        Aos.init({
            duration: 1200,
        })
        // console.log('details mount');
        this.setState((prev) => ({
            ...prev,
            isLoading: true
        }))

        myHttp.GET('/portfolio/active/' + this.props.match.params.slug)
            .then(res => {
                console.log('axios then >> ', res)
                console.log('axios then >> ', res.data[0].category_id.name)
                this.setState(pre => ({
                    ...pre,
                    details: res.data[0],
                    category: res.data[0].category_id,
                    imageArray: res.data[0].image

                }))
                console.log(this.state.imageArray);
            })
            .catch(err => {
                console.log(err);
            })
            .finally(
                this.setState(pre => ({
                    ...pre,
                    isLoading: false
                }))
            )

    }

    // filterCategory = (selectedCat) => {
    //     if (selectedCat === 'ALL') {
    //         this.setState(
    //             preState => ({
    //                 selectedCat: 'ALL'
    //             })
    //         )
    //     } else {
    //         this.setState(
    //             preState => ({
    //                 ...preState,
    //                 selectedCat
    //             })
    //         )
    //     }
    //
    // }
    //
    // closeModal = () => {
    //     this.setState(() => ({
    //         isModalStatus: false
    //     }))
    // }
    //
    // activeModalStatus = (imgUrl) => {
    //     this.setState(() => ({
    //         isModalStatus: true,
    //         imgUrl
    //     }))
    // }

    render() {


        return (
            <>
                <div data-aos="flip-right" id="portfolio-details" className="portfolio-details">
                    <div style={{ 'marginTop': '20px' }} className="container">

                        <div className="row">

                            {this.state.imageArray.length > 0
                                ? <><div className="col-lg-8">
                                    <button onClick={this.props.history.goBack}
                                        style={{ 'size': '20px', 'color': 'black' }}
                                        className="btn-group-lg">Go Back
                                    </button>



                                    <Slide duration={2000} transitionDuration={3000} easing="ease">
                                        {
                                            this.state.imageArray.map(
                                                (img, index) => (
                                                    <div className="each-slide">
                                                        <div style={{ 'backgroundImage': `url(${imageURL}/${img})` }}>

                                                        </div>
                                                    </div>
                                                )
                                            )
                                        }


                                    </Slide>




                                </div>

                                    <div className="col-lg-4 portfolio-info">
                                        <h3><strong><u>{this.state.details.project_title}</u></strong></h3>
                                        <ul>
                                            <li>
                                                <i>{this.state.details.project_client ? this.state.details.project_client + " | " : ''} </i>{
                                                    this.state.category.name}
                                            </li>

                                            {this.state.details.project_date
                                                ? <li><strong>Initial Released:</strong> {this.state.details.project_date}</li>
                                                : ''}

                                            {this.state.details.project_url
                                                ? <li><strong>Project URL</strong>: <a target="_blank"
                                                    href={this.state.details.project_url}>{this.state.details.project_url}</a>
                                                </li>
                                                : ''}

                                        </ul>

                                        <p style={{ textAlign: 'justify' }}>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: this.state.details.description
                                                }}></div>
                                            {/* <div dangerouslySetInnerHTML={{ __html: this.state.details.description }}> </div> */}
                                            {/* {parserr(this.state.details.description)} */}
                                            {/* this.state.details.description */}
                                        </p>
                                    </div>
                                </>
                                : <>

                                    <div className="col-lg-12 portfolio-info">
                                    <button onClick={this.props.history.goBack}
                                        style={{ 'size': '20px', 'color': 'black' }}
                                        className="btn-group-lg">Go Back
                                    </button><br></br><br></br>
                                        <h3><strong><u>{this.state.details.project_title}</u></strong></h3>
                                        <ul>
                                            <li>
                                                <i>{this.state.details.project_client ? this.state.details.project_client + " | " : ''} </i>{
                                                    this.state.category.name}
                                            </li>

                                            {this.state.details.project_date
                                                ? <li><strong>Initial Released:</strong> {this.state.details.project_date}</li>
                                                : ''}

                                            {this.state.details.project_url
                                                ? <li><strong>Project URL</strong>: <a target="_blank"
                                                    href={this.state.details.project_url}>{this.state.details.project_url}</a>
                                                </li>
                                                : ''}

                                        </ul>

                                        <p style={{ textAlign: 'justify' }}>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: this.state.details.description
                                                }}></div>
                                            {/* <div dangerouslySetInnerHTML={{ __html: this.state.details.description }}> </div> */}
                                            {/* {parserr(this.state.details.description)} */}
                                            {/* this.state.details.description */}
                                        </p>
                                    </div>
                                </>
                                }


                        </div>

                    </div>
                </div>
            </>
        )
    }
}

