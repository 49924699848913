import React from 'react';
import myHttp from "../../../util/http";

export class SocialDarkComponent extends React.Component{
    constructor(){
        super();
        this.state = {
            socials:[]
        }
    }

    componentDidMount() {
        myHttp.GET('/social/active')
            .then(res=>{
                this.setState({
                    socials: res.data,
                })
                // console.log(this.state.socials);
            })
            .catch(err=>{
                // console.log(err);
            })
    }

    render(){
        return (
            <>
                <div data-aos="zoom-in" className="social-links">

                    {
                        this.state.socials.map(
                        (social,index) => (
                        <a rel="noreferrer" key={`social_`+index} target="_blank" title={social.name} href={social.url} className="twitter"><i className={social.logo}></i></a>
                        )
                        )
                    }

                </div>
            </>
        )
    }

}