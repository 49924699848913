import React from 'react';
import './resume.dark.component.css';
import myHttp from "../../../util/http";
// import notify from "../../../util/notify";

import { TinyLoader } from 'components/UI/TinyLoader';


import Loader from "components/common/loader/loader";

export class ResumeDarkComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            summaries: '',
            educations: [],
            trainings: [],
            exps: [],
            currs: [],
            isLoadingSummary: true,
            isLoadingEducation: true,
            isLoadingTraining: true,
            isLoadingAcademic: true,
            isLoadingExperience: true,
            isLoadingCurrently: true,

        }
    }

    fetchSummary = e => {
        myHttp.GET('/summary/active', true)
            .then(res => {
                this.setState({
                    summaries: res.data[0],
                })
            })
            .catch(err => {
                // notify.handleError(err)
            })
            .finally(() => {
                this.setState(
                    (preState) => ({
                        ...preState,
                        isLoadingSummary: false
                    }),
                    () => {
                        this.fetchTraining()
                    })
            })
        // .finally(
        //     this.fetchEducation
        // )
    }

    fetchEducation = e => {
        myHttp.GET('/education/active', true)
            .then(res => {
                this.setState({
                    educations: res.data,
                })
            
            })
            .catch(err => {
                // notify.handleError(err)
            })
            .finally(() => {
                this.setState(
                    (preState) => ({
                        ...preState,
                        isLoadingEducation: false
                    }),
                    () => {
                        this.fetchExperiences()
                    })
            })
        // .finally(() => {
        //     this.setState({
        //         isLoadingEducation: false
        //     })

        // }, this.fetchTraining)
        // .finally(
        //     this.fetchTraining
        // )
    }

    fetchTraining = e => {
        myHttp.GET('/training/active', true)
            .then(res => {
                this.setState({
                    trainings: res.data,
                })
            })
            .catch(err => {
                // notify.handleError(err)
            })
            .finally(() => {
                this.setState(
                    (preState) => ({
                        ...preState,
                        isLoadingTraining: false
                    }),
                    () => {
                        this.fetchEducation()
                    })
            })
        // .finally(() => {
        //     this.setState({
        //         isLoadingEducation: false
        //     })

        // }, this.fetchExperiences)

    }

    fetchExperiences = e => {
        myHttp.GET('/expcurr/active/Experiences')
            .then(res => {
                this.setState({
                    exps: res.data,
                })
            })
            .catch(err => {
                // notify.handleError(err)
            })
            .finally(() => {
                this.setState(
                    (preState) => ({
                        ...preState,
                        isLoadingExperience: false
                    }),
                    () => {
                        this.fetchCurrently()
                    })
            })
        // .finally(() => {
        //     this.setState({
        //         isLoadingCurrently: false
        //     })

        // }, this.fetchCurrently)

    }

    fetchCurrently = e => {
        myHttp.GET('/expcurr/active/Currently')
            .then(res => {
                this.setState({
                    currs: res.data,
                })
            })
            .catch(err => {
                // notify.handleError(err)
            })
            .finally(() => {
                this.setState(
                    (preState) => ({
                        ...preState,
                        isLoadingCurrently: false
                    }))
            })
        // .finally(() => {
        //     this.setState({
        //         isLoadingCurrently: false
        //     })
        // })
    }

    componentDidMount() {



        this.setState({
            isLoading: false
        })

        this.fetchSummary();


    }


    render() {
        let content = this.state.isLoading
            ? <Loader />
            : <div className="row">
                <div className="col-lg-6">
                    {
                        this.state.isLoadingSummary
                            ? <TinyLoader msg="Loading..."></TinyLoader>
                            : <>
                                {
                                    this.state.summaries
                                        ? <><h3 className="resume-title">Summary</h3>
                                            <div data-aos="flip-right" className="resume-item pb-0">
                                                <h4>{this.state.summaries.name}</h4>
                                                <p style={{textAlign:'justify'}}><em>{this.state.summaries.message}</em></p>
                                                <p>
                                                    <ul>
                                                        <li>{this.state.summaries.address}</li>
                                                        <li>{this.state.summaries.mobile}</li>
                                                        <li>{this.state.summaries.email}</li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </>
                                        : null
                                }
                            </>
                    }



                    {
                        this.state.isLoadingEducation
                            ? <TinyLoader msg="Loading Education..." />
                            : <>
                                {
                                    this.state.educations.length > 0
                                        ? <>
                                            <h3 className="resume-title">Academic Qualifications</h3>
                                            {
                                                this.state.educations.map(
                                                    (item, index) => (
                                                        <div key={"quali_" + index} data-aos="flip-left" className="resume-item">
                                                            <h4>{item.program}</h4>
                                                            <h5>{item.year}</h5>
                                                            <p><em>{item.university}</em></p>
                                                            <p>{item.message}</p>
                                                        </div>
                                                    )
                                                )
                                            }
                                        </>
                                        : null
                                }
                            </>
                    }



                    {
                        this.state.isLoadingExperience
                            ? <TinyLoader msg="Loading..." />
                            : <>
                                {
                                    this.state.exps.length > 0
                                        ? <>
                                            <h3 className="resume-title">Experiences</h3>

                                            {
                                                this.state.exps.map(
                                                    (item, index) => (
                                                        <div key={"exp_" + index} data-aos="flip-up" className="resume-item">
                                                            <h4>{item.designation}</h4>
                                                            <h5>{item.year}{item.duration ? ' ' + item.duration : ''}</h5>
                                                            <p><em>{item.institute}</em></p>
                                                            <p>
                                                                <ul>
                                                                    {
                                                                        item.message.map(
                                                                            (item, index) => (
                                                                                <li key={"exp_inside_" + index} >{item}
                                                                                </li>
                                                                            )
                                                                        )
                                                                    }
                                                                </ul>
                                                            </p>
                                                        </div>

                                                    )
                                                )
                                            }
                                        </>
                                        : null
                                }
                            </>
                    }




                    {
                        this.state.isLoadingCurrently
                            ? <TinyLoader msg="Loading..." />
                            : <>
                                {
                                    this.state.currs.length > 0
                                        ? <>
                                            <h3 className="resume-title">Currently</h3>

                                            {
                                                this.state.currs.map(
                                                    (item, index) => (
                                                        <div key={"curr_" + index} data-aos="fade-up" className="resume-item">
                                                            <h4>{item.designation}</h4>
                                                            <h5>{item.year}{item.duration ? ' ' + item.duration : ''}</h5>
                                                            <p><em>{item.institute}</em></p>
                                                            <p>
                                                                <ul>
                                                                    {
                                                                        item.message.map(
                                                                            (item, index) => (
                                                                                <li key={"curr_inside_" + index} >{item}
                                                                                </li>
                                                                            )
                                                                        )
                                                                    }
                                                                </ul>
                                                            </p>
                                                        </div>

                                                    )
                                                )
                                            }
                                        </>
                                        : null
                                }
                            </>
                    }






                </div>

                <div className="col-lg-6">
                    <h3 className="resume-title">Trainings</h3>

                    {
                        this.state.isLoadingTraining
                            ? <TinyLoader msg="Trainings are loading..." />
                            : <>
                                {
                                    this.state.trainings.map(
                                        (item, index) => (
                                            <div key={"train_" + index} data-aos="zoom-in" className="resume-item">
                                                <h4>{item.course}</h4>
                                                <h5>{item.year}</h5>
                                                <h5>{item.duration}</h5>
                                                <p><em>{item.institute}</em></p>
                                                <p>
                                                    <ul>
                                                        {
                                                            item.message.map(
                                                                (item, index) => (
                                                                    <li key={"train_inside_" + index} key={`training_msg_` + index}>{item}</li>
                                                                )
                                                            )
                                                        }
                                                    </ul>
                                                </p>
                                            </div>

                                        )
                                    )
                                }
                            </>
                    }

                </div>

            </div>
        return (
            <>
                {/*-- ======= Resume Section ======= --*/}
                <section id="resume" className="resume section-show">
                    <div className="container">

                        <div className="section-title">
                            <h2>Resume</h2>
                            <p>Check My Resume</p>
                        </div>

                        {content}
                    </div>
                </section>
            </>
        )
    }
}