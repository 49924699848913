import React from 'react';
import './service.dark.component.css';
import myHttp from "../../../util/http";
import Loader from "components/common/loader/loader";
import Aos from "aos";
import "aos/dist/aos.css";

export class ServiceDarkComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            services: [],
            isLoading: true
        }
    }

    componentDidMount() {
        Aos.init({
            duration: 1200,
        })

        this.setState({
            isLoading: true
        })
        myHttp.GET('/service/active')
            .then(response => {
                this.setState({
                    services: response.data
                })
            })
            .catch(err => {

            })
            .finally(
                this.setState({
                    isLoading:false
                })
            )
    }


    render() {
        let content = this.state.isLoading
            ? <Loader/>
            : <>
                {
                    this.state.services.map(
                        (item, index) => (
                            <div key={"service_"+index} data-aos="flip-right" className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                                <div className="icon-box">
                                    <div className="icon" style={{backgroundColor: `${item.backgroundColor}`}}><i
                                        style={{color: `${item.color}`}} className={item.logo}></i></div>
                                    <h4><a href="/">{item.title}</a></h4>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        )
                    )
                }
            </>
        return (
            <>
                {/*-- ======= Services Section ======= -*/}
                <section id="services" className="services section-show">
                    <div className="container">

                        <div className="section-title">
                            <h2>Services</h2>
                            <p>My Services</p>
                        </div>

                        <div className="row">
                            {content}
                        </div>

                    </div>
                </section>
            </>
        )
    }
}