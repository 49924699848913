import React from "react";

import { ResumeDarkComponent } from "../../components/frontend_dark/resume/resume.dark.component";
// import {FrontendDarkLayout} from "../../layouts/frontend.dark.layout";
// import FooterFront from "../../components/footer/frontend/footer.front";
import useDocumentTitle from './../../components/useDocumentTitle';

export default function ResumeDarkPage() {
    useDocumentTitle('Resume 🎓 | Web Developer | Portfolio | Sujit Dahal 🧍');
    return (
        <>
            <ResumeDarkComponent />
        </>
    )
}