import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './components/app/app.component'


import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-PRXXF96'
}

TagManager.initialize(tagManagerArgs)


const container = document.getElementById('root');
ReactDOM.render(<App></App>,container);