import React from 'react';
import {Link} from 'react-router-dom';
import http from "../../../util/http";

import notify from "../../../util/notify";

require('./login.component.css');

export class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            data: {
                email: '',
                password: ''
            },
            error: {
                email: '',
                password: ''
            },
            pure: {
                email: true,
                password: true
            },
            remember_me: false,
            isSubmitting: false,
            isValidForm: false
        }
    }

    handleChange = e => {

        const {name, value} = e.target;
        this.setState(preState => ({
            ...preState,
            data: {
                ...preState.data,
                [name]: value
            }

        }), () => {
            this.validateForm(name);
        })

    }



    handleChecked = e => {

        const {checked} = e.target;
        this.setState(preState => ({
            ...preState,
            remember_me: checked

        }), () => {

        })

    }






    validateForm(fieldName) {
        let errMsg;
        switch (fieldName) {
            case 'email':
                errMsg = this.state.data.email
                    ? this.state.data.email.includes('@') && this.state.data.email.includes('.com')
                        ? ''
                        : 'Invalid Email'
                    : 'Email is required'
                break;

            case 'password':
                errMsg = this.state.data.password
                    ? this.state.data.password.length > 6
                        ? ''
                        : 'Must be at least 7 characters'
                    : 'Password is required'
                break;
            default:
                break;

        }
        // errMsg = this.state.data[fieldName]
        //     ? ''
        //     : `${fieldName} is required`
        this.setState(preState => ({
            error: {
                ...preState.error,
                [fieldName]: errMsg
            },
            pure: {
                ...preState.pure,
                [fieldName]: false
            }
        }), () => {
            const {error, pure} = this.state;
            let errors = Object.values(error)
                .filter(error => error);
            let dirty = Object.values(pure)
                .filter(item => item);
            this.setState({
                isValidForm: errors.length === 0 && dirty.length === 0
            })
        })

    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState((prev)=>({
            ...prev,
            isSubmitting:true
        }));
        // notify.handleError('err');
        // axios.post('http://localhost:8888/api/auth/login',this.state.data,{responseType:'json'})
            http.POST('/auth/login',this.state.data)
            
            .then(res =>{
                localStorage.setItem('token',res.data.token);
                localStorage.setItem('user',JSON.stringify(res.data.user));
                this.props.history.push('/dashboard');
            })
            .catch(err => {


                this.setState(prev  => ({
                    data:{
                        ...prev.data,
                        email:'',
                        password:''
                    },
                    isValidForm:false,
                    isSubmitting:false,
                    pure:{
                        ...prev.pure,
                        email: true,
                        password: true,

                    }
                }))

                notify.handleError(err);

            })
    }

    render() {
        let btn = this.state.isSubmitting
            ? <button disabled type="button" name="button"
                      className="btn login_btn">Processing.......</button>
            : <button disabled={!this.state.isValidForm} type="submit" name="button"
                      className="btn login_btn">Login</button>
        return <div className="container h-100">
            <div className="d-flex justify-content-center h-100">
                <div className="user_card">
                    <div className="d-flex justify-content-center">
                        <div className="brand_logo_container">
                            <img height="100px"
                                 src="https://upload.wikimedia.org/wikipedia/commons/3/33/Vanamo_Logo.png"
                                 className="brand_logo" alt="Logo"/>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center form_container">
                        <form onSubmit={this.handleSubmit}>
                            <div className="input-group mb-1">
                                <div className="input-group-append">
                                    <span className="input-group-text"><i className="fas fa-envelope"></i></span>
                                </div>
                                <input value={this.state.data.email} type="text" name="email" className="form-control input_user"
                                       placeholder="Email" onChange={this.handleChange}/>
                            </div>
                            <p className="error">{this.state.error.email}</p>

                            <div className="input-group mb-2">
                                <div className="input-group-append">
                                    <span className="input-group-text"><i className="fas fa-key"></i></span>
                                </div>
                                <input value={this.state.data.password} type="password" name="password" className="form-control input_pass"
                                       placeholder="Password" onChange={this.handleChange}/>
                            </div>
                            <p className="error">{this.state.error.password}</p>


                           
                            <div className="d-flex justify-content-center mt-3 login_container">
                                {btn}
                            </div>
                        </form>
                    </div>

                    <div className="mt-4">
                     
                        <div className="d-flex justify-content-center links">
                            <Link to="/forgot-password">Forgot your password?</Link>
                        </div>
                        <div className="d-flex justify-content-center links">
                            <Link to="/">Home</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}