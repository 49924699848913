import React from 'react'
import myHttp from "../../../util/http";
import notify from "../../../util/notify";
import Loader from "components/common/loader/loader";
import dateUtil from "util/dateUtil";
import {Link} from 'react-router-dom';

// import ReactPaginate from 'react-paginate';
import './list.user.component.css';

const imageUrl = process.env.REACT_APP_IMG_URL;


export class ListUserComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            users: [],
            searchTerm: '',
        }
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        })
        myHttp.GET('/user', true)
            .then(res => {
                this.setState({
                    users: res.data,
                })
            })
            .catch(err => {
                notify.handleError(err)
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    edit = (id) => {
        this.props.routeProps.history.push(`/user/${id}/edit`)
    }

    remove(id, index) {
        // eslint-disable-next-line no-restricted-globals
        let confirmation = confirm("Are you sure to remove the selection?" + id);
        if (confirmation) {
            myHttp.DELETE(`/user/${id}`, true)
                .then(res => {
                    notify.showSuccess('Deleted Successfully');
                    const {users} = this.state;
                    users.splice(index, 1);
                    this.setState({
                        users
                    })
                })
                .catch(err => {
                    notify.handleError(err);
                    // console.log('err frontend bata list.user.component.js bata')
                })
        }
    }

    handleSearch = (e) => {
        this.setState((prev) => ({
            searchTerm: e.target.value
        }), () => {
        })
    }


    render() {
        let content = this.state.isLoading
            ? <>
                <tr>
                    <td colSpan="12"><Loader/></td>
                </tr>


            </>
            : <>

                {this.state.users.filter(obj => {
                    if (this.state.searchTerm === "") {
                        return obj;
                    } else if (
                        obj.name.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
                        obj.email.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
                        obj.status.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
                        obj.role.toLowerCase().includes(this.state.searchTerm.toLowerCase())
                    ) {
                        return obj;
                    }

                    // {
                    //     this.state.users.some(function(user,index){
                    //         if(user.name){
                    //             return true;
                    //         }
                    //     })

                }).map((user, index) => (
                    <tr key={user._id}>
                        <th scope="row">{index + 1}</th>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.status}</td>
                        <td>{user.role}</td>

                        <td>
                            {
                                user.avatar
                                    ? <a rel="noreferrer" target="_blank" href={`${imageUrl}/${user.avatar}`}><img
                                        src={`${imageUrl}/${user.avatar}`} height="60px" alt="User Avatar"/></a>
                                    : 'No Avatar'
                            }

                        </td>

                        <td>
                            {dateUtil.formatDate(user.createdAt)} <br/>
                            {dateUtil.formatTime(user.createdAt)}
                        </td>
                        <td>
                            <button className="btn btn-info" onClick={() => this.edit(user._id)}>Edit</button>
                            <button className="btn btn-danger" onClick={() => this.remove(user._id, index)}>Del</button>
                        </td>
                    </tr>
                ))}


            </>
        return (<>

            <div className="breadcrumb d-flex justify-content-between align-items-center">
                <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item"><Link to="#">{this.props.breadcumb[0]}</Link></li>
                    <li className="breadcrumb-item active">{this.props.breadcumb[1]}</li>
                    {this.props.breadcumb[2]
                        ? <li className="breadcrumb-item active">{this.props.breadcumb[2]}</li>
                        : ''
                    }

                </ol>
                <h4 className="text-center mytitle">{this.props.title}</h4>
                <Link to="/user/create" className="btn btn-sm btn-success">{this.props.btnTitle}</Link>
            </div>

            <div>
                <div className="form-group">
                    <input className="form-control" onChange={this.handleSearch} type="text"
                           placeholder="Search Data"/>
                </div>
                <table className="table">
                    <thead className="thead-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Status</th>
                        <th scope="col">Role</th>
                        <th scope="col">Avatar</th>
                        <th scope="col">Created On</th>
                        <th scope="col">Actions</th>

                    </tr>
                    </thead>
                    <tbody>
                    {content}

                    </tbody>
                </table>


            </div>

        </>)
    }
}


