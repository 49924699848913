import React from "react";

import { ContactDarkComponent } from "../../components/frontend_dark/contact/contact.dark.component";
// import {FrontendDarkLayout} from "../../layouts/frontend.dark.layout";
// import FooterBackend from "../../components/footer/backend/footer.backend";

import useDocumentTitle from './../../components/useDocumentTitle';

// export default class ContactDarkPage extends React.Component {

//     render() {
//         return (
//             <>
//                 <ContactDarkComponent />
//             </>
//         )
//     }

// }

export default function ContactDarkPage() {
    useDocumentTitle('Contact ☎️ | Web Developer | Portfolio | Sujit Dahal 🧍')
    return (
        <>
            <ContactDarkComponent />
        </>
    )
}