import React from "react";
import {BackendLayout} from "../../../../layouts/backend.layout";
import {CreateSummaryComponent} from "../../../../components/backend/summary/create.summary.component";

export class CreateSummaryPage extends React.Component{
    // constructor(){
    //     super();
    //
    //     this.state = {
    //         test:''
    //     }
    // }

    componentDidMount() {

    }

    render(){
        return(
            <>
                <BackendLayout>
                    <CreateSummaryComponent
                        title="Create Summary"
                        routeProps ={this.props}
                        breadcumb={['Resume','Summary','Create']}
                        btnTitle="List Summary"
                        btnTo="/admin/summary">
                    </CreateSummaryComponent>
                </BackendLayout>
            </>
        )
    }
}
