import React from 'react';

import {Routing} from './app.routing';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const App = () => {
    return <div>
        <ToastContainer/>
        <Routing/>
    </div>
}

