import React from "react";
import {BackendLayout} from "../../../../layouts/backend.layout";
import {ListEducationComponent} from "../../../../components/backend/education/list.education.component";

export class ListEducationPage extends React.Component{


    render(){
        return(
            <>

                <BackendLayout>
                    <ListEducationComponent
                        title="List Education"
                        routeProps={this.props}
                        breadcumb={['Education','List']}

                        btnTitle="Create Education"
                        btnTo="/admin/education/create">

                    </ListEducationComponent>
                </BackendLayout>

            </>
        )
    }
}
