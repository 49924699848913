import React, { useState, useEffect } from 'react';
import { AboutDarkComponent } from 'components/frontend_dark/about/aboutme/about.dark.component';
import { StatDarkComponent } from "../../components/frontend_dark/about/stat/stat.dark.component";
import { SkillDarkComponent } from "../../components/frontend_dark/about/skill/skill.dark.component";
import { InterestDarkComponent } from "../../components/frontend_dark/about/interest/interest.dark.component";
import { TestimonialDarkComponent } from "../../components/frontend_dark/about/testimonial/testimonial.dark.component";
// import {FrontendDarkLayout} from "../../layouts/frontend.dark.layout";
// import FooterBackend from "../../components/footer/backend/footer.backend";
import Loader from "components/common/loader/loader";

import { TinyLoader } from "components/UI/TinyLoader";
import useDocumentTitle from './../../components/useDocumentTitle'


export default function AboutDarkPage() {
    // constructor() {
    //     super();
    //     this.state = {
    //         isLoading: false
    //     }
    // }

    let content = <>
            <AboutDarkComponent />
            <StatDarkComponent />
            <SkillDarkComponent />
            <InterestDarkComponent />
            <TestimonialDarkComponent />
        </>
    // useDocumentTitle('Other title 👾')
    useDocumentTitle('About ⭐ | Web Developer | Portfolio | Sujit Dahal 🧍')

    return (
        <>

            <section id="about" className="about section-show">
                {content}
            </section>

        </>
    )


}



// export default function AboutDarkPage() {



//     let content = this.state.isLoading
//         ? <TinyLoader msg="Loading..." />
//         : <>
//             <AboutDarkComponent />
//             <StatDarkComponent />
//             <SkillDarkComponent />
//             <InterestDarkComponent />
//             <TestimonialDarkComponent />
//         </>
//     useDocumentTitle('Other title 👾')
//     return (
//         <>

//             <section id="about" className="about section-show">
//                 {content}
//             </section>

//         </>
//     )


// }