import React from 'react';
import {TestimonialForm} from 'components/backend/testimonial/form.testimonial.component';
import notify from "../../../util/notify";
import myHttp from "../../../util/http";
import Loader from "../../common/loader/loader";


export class EditTestimonialComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
            isLoading: false,
            testimonialX: ''
        }
    }

    componentDidMount() {
        this.testimonialId = this.props.routeProps.match.params['id'];
        this.setState({
            isLoading: true
        })
        myHttp.GET(`/testimonial/${this.testimonialId}`, true)
            .then(res => {
                this.setState({

                    testimonialX: {...res.data,preview:res.data.image,image:''}
                })
            })
            .catch(err => notify.handleError(err))
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }


    editFunction = (data, files) => {
        this.setState({
            isSubmitting: true
        })
        myHttp.UPLOAD('PUT', `/testimonial/${this.testimonialId}`, data, files, 'image', true)
            .then(res => {
                this.props.routeProps.history.push('/admin/testimonial');
                notify.showSuccess('Testimonial updated successfully');
            })
            .catch(err => {
                notify.handleError(err);
            })
            .finally(() => {
                this.setState({
                    isSubmitting: false
                })
            })
    }


    render() {
        let content = this.state.isLoading
            ? <>
               <Loader/>


            </>
            : <>
                {/*difference ko lagi khi lageko natra render hudaina ani load hudaina data haru form ma edit garda*/}
                <TestimonialForm title={this.props.title}
                          breadcumb={this.props.breadcumb}
                          btnTitle={this.props.btnTitle}
                          btnTo={this.props.btnTo}
                          submitCallback={this.editFunction}
                          testimonialX={this.state.testimonialX}
                          isLoading={this.state.isLoading}/>

            </>
        return (content)
    }

}

