import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
import {CreateCategoryComponent} from "../../../components/backend/category/create.category.component";

export class CreateCategoryPage extends React.Component{

    render(){
        return(
            <>
                <BackendLayout>
                    <CreateCategoryComponent
                        title="Create Category"
                        routeProps ={this.props}
                        breadcumb={['Category','Create']}
                        btnTitle="List Category"
                        btnTo="/admin/category">
                    </CreateCategoryComponent>
                </BackendLayout>
            </>
        )
    }
}
