import React from "react";

import { ServiceDarkComponent } from "../../components/frontend_dark/service/service.dark.component";

import useDocumentTitle from './../../components/useDocumentTitle';

// export default class ServiceDarkPage extends React.Component {


//     render() {
//         return (
//             <>

//                 <ServiceDarkComponent/>


//             </>
//         )
//     }

// }

export default function ServiceDarkPage() {
    useDocumentTitle('Services ⚒️ | Web Developer | Portfolio | Sujit Dahal 🧍')
    return (
        <>
            <ServiceDarkComponent />
        </>
    )
}