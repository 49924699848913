import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
// import {CreateTestimonialComponent} from "../../../components/backend/testimonial/create.testimonial.component";
// import {CreateSocialComponent} from "../../../components/backend/social/create.social.component";
import {CreateAboutComponent} from "../../../components/backend/about/create.about.component";

export class CreateAboutPage extends React.Component{
    // constructor(){
    //     super();
    //
    //     this.state = {
    //         test:''
    //     }
    // }

    componentDidMount() {

    }

    render(){
        return(
            <>
                <BackendLayout>
                    <CreateAboutComponent
                        title="Create About"
                        routeProps ={this.props}
                        breadcumb={['About','Create']}
                        btnTitle="List About"
                        btnTo="/admin/about">
                    </CreateAboutComponent>
                </BackendLayout>
            </>
        )
    }
}
