import React, {useEffect, useState} from "react";

import {Link} from 'react-router-dom';

import Loader from 'components/common/loader/loader';

import {Formik, Form, Field, ErrorMessage} from 'formik'

import './form.portfolio.component.css';

import {object, string, mixed, number} from 'yup';
import {Submitting} from "../../UI/Submitting";
import {Input} from "antd";
import {DropzoneArea} from 'material-ui-dropzone';
import {DropzonePortfolioComponent} from "./dropzone.portfolio.component";


import Dropzone from "react-dropzone"
import Thumb from "./Thumb";
import myHttp from "../../../util/http";
import notify from "../../../util/notify";
//


const imgUrl = process.env.REACT_APP_IMG_URL;

const dropzoneStyle = {
    width: "100%",
    height: "auto",
    borderWidth: 2,
    borderColor: "rgb(102, 102, 102)",
    borderStyle: "dashed",
    borderRadius: 5,
}

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

function PortfolioForm(props) {

    const [formValues, setFormValues] = useState(null);
    const [editForm, setEditForm] = useState(false);
    const [isNewImage, setIsNewImage] = useState(false);

    const [files, setFiles] = useState([])


    // const {getRootProps, getInputProps} = useDropzone({
    //     accept: "image/*",
    //     onDrop: (acceptedFiles) => {
    //         setFiles(
    //             acceptedFiles.map((file) =>
    //                 Object.assign(file, {
    //                     preview: URL.createObjectURL(file),
    //                 })
    //             )
    //         )
    //     },
    // })

    const images = files.map((file) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="preview"/>
            </div>
        </div>
    ))

    const validate = object({
        category_id: string()
            .required('Category is required'),

        project_title: string()
            .required('Project Title is required')
            .max(100, 'Must be 100 characters or less')
            .min(2, 'Must be 2 characters or more'),

        project_date: string()
            .max(100, 'Must be 100 characters or less')
            .min(3, 'Must be 3 characters or more'),

        project_client: string()
            .max(100, 'Must be 100 characters or less')
            .min(3, 'Must be 3 characters or more'),

        project_url: string()
            .max(500, 'Must be 500 characters or less')
            .min(3, 'Must be 3 characters or more'),

        description: string()
            .max(1200, 'Must be 1200 characters or less')
            .min(3, 'Must be 3 characters or more'),

        status: mixed()
            .oneOf(['Active', 'Inactive'])
            .required('Status is required')

        // image: mixed()
        //     .required('Image is required')
        //     .test("size", "File is too large", (value) => {
        //         console.log('sizeee >> ', value);
        //         return value && value.size <= 1 * 1000 * 1000;
        //     })
        //     .test("type", "Supportive file formats required:.jpeg, .jpg, .png", (value) => {
        //         console.log('vallllllllllllllueee >> ', value)
        //
        //         return value && (
        //             value.type === "image/jpeg" ||
        //             value.type === "image/jpg"
        //         )
        //     })


    })


    const initialValues = {
        project_title: '',
        project_client: '',
        project_date: '',
        project_url: '',
        description: '',
        status: '',
        category_id: '',

        currently: '',
        image: [],
        preview: '',
        filesToUpload: [],
        files: [],
    }

    // VALIDATE Only if New Image is Selected then goes for validation
    if (isNewImage) {
        validate.fields.image = mixed()
            .required('Image is required')
            .test("size", "File is too large", (value) => {
                return value && value.size <= 1 * 1000 * 1000;
            })
            .test("type", "Supportive file formats required:.jpeg, .jpg, .png", (value) => {

                return value && (
                    value.type === "image/jpeg" ||
                    value.type === "image/jpg"
                )
            })
        validate._nodes.push('image');
    }


    useEffect(() => {
        // console.log('props edit data >> ', props.tableData)
        if (props.tableData) {
            setFormValues(props.tableData);
            setEditForm(true);
        }

    }, [props.tableData])




    return (
        <>

            <div className="breadcrumb d-flex justify-content-between align-items-center">
                <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item"><Link to="#">{props.breadcumb[0]}</Link></li>
                    <li className="breadcrumb-item active">{props.breadcumb[1]}</li>
                </ol>
                <h4 className="text-center mytitle">{props.title}</h4>
                <Link to={props.btnTo} className="btn btn-sm btn-success">{props.btnTitle}</Link>
            </div>


            <Formik
                initialValues={formValues || initialValues}
                validationSchema={validate}
                enableReinitialize
                onSubmit={
                    (values, submitProps) => {
                        // console.log('image.... >> ', values.image);
                        // console.log('form values >> ', values);
                        submitProps.setSubmitting(true);
                        props.submitCallback(values, values.image);
                        submitProps.resetForm(true);
                        submitProps.setSubmitting(false);
                    }
                }
            >
                {
                    (formikProps, setFieldValue, field) => (
                        <>
                            {/*{console.log('formik props >> ',formikProps)}*/}
                            {


                                props.isLoading
                                    ? <Loader/>
                                    : <Form noValidate>

                                        <div className="form-row form-group">

                                            <div className="col-md-12 mb-12">
                                                <label htmlFor="validationServerUsername">Category*</label>
                                                <div className="form-group">
                                                    <Field as="select" name="category_id"
                                                           className={formikProps.touched.category_id
                                                               ? formikProps.errors.category_id
                                                                   ? 'form-control is-invalid'
                                                                   : 'form-control is-valid'
                                                               : 'form-control'
                                                           }
                                                           required>
                                                        <option value="">Select Category</option>
                                                        {
                                                            props.tableData
                                                                ? <option
                                                                    value={props.tableData.category_id}>{props.tableData.category_id.name}</option>
                                                                : undefined
                                                        }
                                                        {
                                                            props.mainCat
                                                                ? props.mainCat.map(
                                                                (item, index) => (
                                                                    <option value={item._id}>{item.name}</option>
                                                                )
                                                                )
                                                                : undefined
                                                        }
                                                    </Field>
                                                    <ErrorMessage name="category_id" component="div"
                                                                  className="invalid-feedback"></ErrorMessage>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="form-row form-group">
                                            <div className="col-md-5 mb-12">
                                                <label className="label-color">Project Title*</label>
                                                <Field type="text"
                                                       className={formikProps.touched.project_title
                                                           ? formikProps.errors.project_title
                                                               ? 'form-control is-invalid'
                                                               : 'form-control is-valid'
                                                           : 'form-control'
                                                       }

                                                       id="validationServer01"
                                                       name="project_title"
                                                       placeholder="Project Title"
                                                       autoComplete="off"
                                                />
                                                <ErrorMessage name="project_title" component="div"
                                                              className="invalid-feedback"></ErrorMessage>
                                            </div>


                                            <div className="col-md-5 mb-12">
                                                <label htmlFor="validationServer02">Project Date<i style={{color: 'blue'}}
                                                                                                   className={formikProps.values.logo}></i>
                                                </label>
                                                <Field type="text"
                                                       className={formikProps.touched.project_date
                                                           ? formikProps.errors.project_date
                                                               ? 'form-control is-invalid'
                                                               : 'form-control is-valid'
                                                           : 'form-control'
                                                       }
                                                       id="validationServer02"
                                                       placeholder="Project Date"
                                                       name="project_date"
                                                       autoComplete="off"
                                                />
                                                <ErrorMessage name="project_date" component="div"
                                                              className="invalid-feedback"></ErrorMessage>

                                            </div>


                                            <div className="col-md-2 mb-12">
                                                <label htmlFor="validationServerUsername">Status*</label>
                                                <div className="form-group">
                                                    <Field as="select" name="status"
                                                           className={formikProps.touched.status
                                                               ? formikProps.errors.status
                                                                   ? 'form-control is-invalid'
                                                                   : 'form-control is-valid'
                                                               : 'form-control'
                                                           }
                                                           required>
                                                        <option value="">Select Status</option>
                                                        <option value="Active">Active</option>
                                                        <option value="Inactive">Inactive</option>
                                                    </Field>
                                                    <ErrorMessage name="status" component="div"
                                                                  className="invalid-feedback"></ErrorMessage>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group form-row ">

                                            <div className="col-md-4 mb-12">
                                                <label className="label-color">Project Clients</label>
                                                <Field type="text"
                                                       className={formikProps.touched.project_client
                                                           ? formikProps.errors.project_client
                                                               ? 'form-control is-invalid'
                                                               : 'form-control is-valid'
                                                           : 'form-control'
                                                       }

                                                       id="validationServer01"
                                                       name="project_client"
                                                       placeholder="Project Client"
                                                       autoComplete="off"
                                                />
                                                <ErrorMessage name="project_client" component="div"
                                                              className="invalid-feedback"></ErrorMessage>
                                            </div>
                                            <div className="col-md-8 mb-12">
                                                <label className="label-color">Project URL</label>
                                                <Field type="text"
                                                       className={formikProps.touched.project_url
                                                           ? formikProps.errors.project_url
                                                               ? 'form-control is-invalid'
                                                               : 'form-control is-valid'
                                                           : 'form-control'
                                                       }

                                                       id="validationServer01"
                                                       name="project_url"
                                                       placeholder="Project URL"
                                                       autoComplete="off"
                                                />
                                                <ErrorMessage name="project_url" component="div"
                                                              className="invalid-feedback"></ErrorMessage>
                                            </div>
                                        </div>

                                        <div className="form-group form-row ">

                                            <div className="col-md-12 mb-12">
                                                <label className="label-color">Description</label>
                                                <Field as="textarea"
                                                       rows={15}
                                                       className={formikProps.touched.description
                                                           ? formikProps.errors.description
                                                               ? 'form-control is-invalid'
                                                               : 'form-control is-valid'
                                                           : 'form-control'
                                                       }

                                                       id="validationServer01"
                                                       name="description"
                                                       placeholder="Project Description"
                                                       autoComplete="off"
                                                />
                                                <ErrorMessage name="description" component="div"
                                                              className="invalid-feedback"></ErrorMessage>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            {
                                                formikProps.values.currently
                                                    ? <p>Currently&nbsp;
                                                        {
                                                            props.tableData.currently.map(
                                                                (curr, index) => (
                                                                   <button onClick={()=>props.delImageCallBack(formikProps.values._id,index)}><img className="img-mythumbnail"
                                                                         src={`${imgUrl}/${curr}`}
                                                                         alt="Interest Pic" width="150px" height="150px"/>
                                                                   </button>
                                                                )
                                                            )
                                                        }

                                                    </p>
                                                    : ''
                                            }

                                            {

                                                formikProps.values.preview && isNewImage
                                                    ? <p>Preview&nbsp;
                                                        <img className="img-thumbnail"
                                                             src={formikProps.values.preview}
                                                             alt="Testimonial Pic" width="200px" height="150px"/>
                                                    </p>
                                                    : ''
                                            }
                                        </div>


                                        <div className="form-group">
                                            <DropzoneArea
                                                filesLimit={8}
                                                acceptedFiles={['image/*']}
                                                maxFileSize={15*1024*1024*8}
                                                dropzoneText={"Drag and drop an image here or click"}
                                                onChange={(files) => {
                                                    // console.log('fileee >>', files)
                                                    formikProps.setFieldValue('image', files)
                                                }
                                                }
                                            />

                                        </div>


                                        {/*<div className="form-group">*/}
                                        {/*    <div className="my-app">*/}
                                        {/*        <div {...getRootProps()}>*/}
                                        {/*            <input {...getInputProps()} />*/}
                                        {/*            <p  style={{'border':'2px solid gray','padding':'20px'}} className='text-center'>Drop files here</p>*/}
                                        {/*        </div>*/}
                                        {/*        <div>{images}</div>*/}
                                        {/*    </div>*/}
                                        {/*    /!*<DropzonePortfolioComponent/>*!/*/}
                                        {/*    <ErrorMessage className="invalid-feedback" name="image" component="div"*/}
                                        {/*    ></ErrorMessage>*/}

                                        {/*</div>*/}

                                        <div className="form-group">
                                            {
                                                props.isSubmitting
                                                    ? <Submitting msg="Submitting... Please Wait!!!"/>
                                                    // :<button className="btn btn-primary"
                                                    : <button className="btn btn-primary btn-lg btn-block"
                                                              disabled={formikProps.isValid && formikProps.dirty ? false : true}
                                                              type="submit">Submit
                                                    </button>
                                            }
                                        </div>

                                    </Form>

                            }


                        </>
                    )
                }
            </Formik>
            <br/>


        </>
    )
}

export {PortfolioForm}

