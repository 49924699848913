import React from "react";
// import {CreateEducationComponent} from "../../../../components/backend/education/create.education.component";
import {BackendLayout} from "../../../../layouts/backend.layout";
import {CreateTrainingComponent} from "../../../../components/backend/training/create.training.component";

export class CreateTrainingPage extends React.Component{

    render(){
        return(
            <>
                <BackendLayout>
                    <CreateTrainingComponent
                        title="Create Training"
                        routeProps ={this.props}
                        breadcumb={['Resume','Training','Create']}
                        btnTitle="List Training"
                        btnTo="/admin/training">
                    </CreateTrainingComponent>
                </BackendLayout>
            </>
        )
    }
}
