import React from 'react';


export const DashboardPage = () => {
    return (<>

        <h2>This is Dashboard Page</h2>


    </>)
}

