import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
// import {CreateInterestComponent} from "../../../components/backend/interest/create.interest.component";
import {CreateServiceComponent} from "../../../components/backend/service/create.service.component";

export class CreateServicePage extends React.Component{

    render(){
        return(
            <>
                <BackendLayout>
                    <CreateServiceComponent
                        title="Create Service"
                        routeProps ={this.props}
                        breadcumb={['Service','Create']}
                        btnTitle="List Service"
                        btnTo="/admin/service">
                    </CreateServiceComponent>
                </BackendLayout>
            </>
        )
    }
}
