import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";

import {EditSkillComponent} from "../../../components/backend/skill/edit.skill.component";

export class EditSkillPage extends React.Component {




    render() {
        return (<>
            <BackendLayout>
                <EditSkillComponent
                    title={'Edit Skill'}
                    breadcumb={['Skill','Edit']}
                    btnTitle={'List Skill'}
                    btnTo={'/admin/skill'}
                    routeProps={this.props}>

                </EditSkillComponent>
            </BackendLayout>
        </>)
    }
}
