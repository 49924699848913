import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";

import {EditServiceComponent} from "../../../components/backend/service/edit.service.component";

export class EditServicePage extends React.Component {




    render() {
        return (<>
            <BackendLayout>
                <EditServiceComponent
                    title={'Edit Service'}
                    breadcumb={['Service','Edit']}
                    btnTitle={'List Service'}
                    btnTo={'/admin/service'}
                    routeProps={this.props}>

                </EditServiceComponent>
            </BackendLayout>
        </>)
    }
}
