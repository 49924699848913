import React, {useEffect, useState} from "react";

import {Link} from 'react-router-dom';

import Loader from 'components/common/loader/loader';
import {Formik, Form,  Field, ErrorMessage} from 'formik'

import './form.home.component.module.css';
import Input from "@material-ui/core/Input";

import {object, string, mixed} from 'yup';
import {Submitting} from "../../UI/Submitting";

const imgUrl = process.env.REACT_APP_IMG_URL;


function HomeForm(props) {

    const [formValues, setFormValues] = useState(null);
    // const [editForm, setEditForm] = useState(false);
    const [isNewImage, setIsNewImage] = useState(false);

    const validate = object({
        name: string()
            .required('Name is required')
            .max(25, 'Must be 25 characters or less')
            .min(3, 'Must be 3 characters or more'),
        iam: string()
            .required('I am field is required')
            .min('2', 'Must be 2 characters or more'),

        // image: mixed()
        //     .required('Image is required')
        //     .test("size", "File is too large", (value) => {
        //         console.log('sizeee >> ', value);
        //         return value && value.size <= 1 * 1000 * 1000;
        //     })
        //     .test("type", "Supportive file formats required:.jpeg, .jpg, .png", (value) => {
        //         console.log('vallllllllllllllueee >> ', value)
        //
        //         return value && (
        //             value.type === "image/jpeg" ||
        //             value.type === "image/jpg"
        //         )
        //     })


    })


    const initialValues = {
        name: '',
        iam: '',
        image: '',
        preview: '',
        currently: ''
    }

    // VALIDATE Only if New Image is Selected then goes for validation
    if (isNewImage) {
        validate.fields.image = mixed()
            .required('Image is required')
            .test("size", "File is too large", (value) => {
                console.log('sizeee >> ', value);
                return value && value.size <= 1 * 1000 * 1000;
            })
            .test("type", "Supportive file formats required:.jpeg, .jpg, .png", (value) => {
                console.log('vallllllllllllllueee >> ', value)

                return value && (
                    value.type === "image/jpeg" ||
                    value.type === "image/png" ||
                    value.type === "image/jpg"
                )
            })
        validate._nodes.push('image');
    }


    useEffect(() => {
        if (props.tableData) {
            setFormValues(props.tableData);
            // setEditForm(true);
        }
    },[props.tableData])


    return (
        <>

            <div className="breadcrumb d-flex justify-content-between align-items-center">
                <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item"><Link to="#">{props.breadcumb[0]}</Link></li>
                    <li className="breadcrumb-item active">{props.breadcumb[1]}</li>
                </ol>
                <h4 className="text-center mytitle">{props.title}</h4>
                <Link to={props.btnTo}></Link></div>


            <Formik
                initialValues={formValues || initialValues}
                validationSchema={validate}
                enableReinitialize
                onSubmit={
                    (values, submitProps) => {
                        // console.log('formik values >> ', values);
                        submitProps.setSubmitting(true);
                        props.submitCallback(values, null);
                        submitProps.resetForm(true);
                        submitProps.setSubmitting(false);
                    }
                }
            >
                {
                    (formikProps, setFieldValue, field) => (
                        <>
                            {/*{console.log('formik props >> ',formikProps)}*/}
                            {/*{console.log('Table Data props >> ',props.tableData)}*/}
                            {
                                props.isLoading
                                    ? <Loader/>
                                    : <Form noValidate>
                                        <div className="form-row form-group">
                                            <div className="col-md-4 mb-12">
                                                <label className="label-color">Name*</label>
                                                <Field type="text"
                                                       className={formikProps.touched.name
                                                           ? formikProps.errors.name
                                                               ? 'form-control is-invalid'
                                                               : 'form-control is-valid'
                                                           : 'form-control'
                                                       }

                                                       id="validationServer01"
                                                       name="name"
                                                       placeholder="Full Name"
                                                       autoComplete="off"
                                                />
                                                <ErrorMessage name="name" component="div"
                                                              className="invalid-feedback"></ErrorMessage>
                                            </div>
                                            <div className="col-md-8 mb-12">
                                                <label htmlFor="validationServer02">I am* [separated with
                                                    commas]</label>
                                                <Field type="text"
                                                       className={formikProps.touched.iam
                                                           ? formikProps.errors.iam
                                                               ? 'form-control is-invalid'
                                                               : 'form-control is-valid'
                                                           : 'form-control'
                                                       }
                                                       id="validationServer02"
                                                       placeholder="I am dami...[MEAN Stack developer] ... [from KTM Nepal] "
                                                       name="iam"
                                                       autoComplete="off"
                                                />
                                                <ErrorMessage name="iam" component="div"
                                                              className="invalid-feedback"></ErrorMessage>

                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="col-md-12 mb-12">
                                                {
                                                    formikProps.values.currently
                                                        ? <p>Currently:?&nbsp;
                                                            <img className="img-thumbnail"
                                                                 src={`${imgUrl}/${formikProps.values.currently}`}

                                                                 alt="Testimonial Pic" width="200px" height="150px"/>
                                                        </p>
                                                        : ''
                                                }


                                                {


                                                    formikProps.values.preview && isNewImage
                                                        ? <p>Selected Preview&nbsp;
                                                            <img className="img-thumbnail"
                                                                 src={formikProps.values.preview}
                                                                 alt="Testimonial Pic" width="200px" height="150px"/>
                                                        </p>
                                                        : ''
                                                }


                                                {/*{*/}
                                                {/*    props.editComponent && formikProps.values.preview && isNewImage*/}
                                                {/*        ? <p>Currently&nbsp;*/}
                                                {/*            <img className="img-thumbnail"*/}
                                                {/*                 src={`${imgUrl}/${formikProps.values.preview}`}*/}

                                                {/*                 alt="Home Pic" width="200px" height="150px"/>*/}
                                                {/*        </p>*/}
                                                {/*        : props.editComponent && formikProps.values.preview*/}
                                                {/*        ? <p>Preview&nbsp;*/}
                                                {/*            <img className="img-thumbnail"*/}
                                                {/*                 src={`${formikProps.values.preview}`}*/}
                                                {/*                 alt="Testimonial Pic" width="200px" height="150px"/>*/}
                                                {/*        </p>*/}
                                                {/*        : ''*/}
                                                {/*}*/}
                                            </div>

                                        </div>

                                        <div className="form-row form-group">
                                            <div className="col-md-12 mb-12">
                                                <div className="custom-file col-md-12">
                                                    <Input
                                                        onChange={(event) => {
                                                            setIsNewImage(true);
                                                            formikProps.setTouched({
                                                                image: true
                                                            })
                                                            if (event.target.files[0]) {
                                                                formikProps.setFieldValue("preview",
                                                                    URL.createObjectURL(event.target.files[0]));
                                                                formikProps.setFieldValue("image", event.target.files[0]);
                                                            }
                                                        }}
                                                        type="file"
                                                        name="image"
                                                        className={formikProps.touched.image
                                                            ? formikProps.errors.image
                                                                ? 'custom-file-input is-invalid'
                                                                : 'custom-file-input is-valid'
                                                            : 'custom-file-input'
                                                        }
                                                        id="validatedCustomFile"/>
                                                    <label className="custom-file-label" htmlFor="validatedCustomFile">
                                                        Choose Image file...</label>
                                                    <ErrorMessage className="invalid-feedback" name="image"
                                                                  component="div"
                                                    ></ErrorMessage>


                                                </div>
                                            </div>

                                        </div>

                                        <div className="form-row">
                                            <div className="col-md-12 mb-12">
                                                {
                                                    props.isSubmitting
                                                        ? <Submitting msg="Submitting... Please Wait!!!"/>
                                                        // :<button className="btn btn-primary"
                                                        : <button className="btn btn-primary btn-lg btn-block"
                                                                  disabled={formikProps.isValid && formikProps.dirty ? false : true}
                                                                  type="submit">Submit
                                                        </button>
                                                }
                                            </div>

                                        </div>


                                    </Form>
                            }


                        </>
                    )
                }
            </Formik>
            <br/>


        </>
    )
}

export {HomeForm}
