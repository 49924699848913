import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
import {CreateContactComponent} from "../../../components/backend/contact/create.contact.component";

export class CreateContactPage extends React.Component{
    // constructor(){
    //     super();
    //
    //     this.state = {
    //         test:''
    //     }
    // }

    componentDidMount() {

    }

    render(){
        return(
            <>
                <BackendLayout>
                    <CreateContactComponent
                        title="Create Contact"
                        routeProps ={this.props}
                        breadcumb={['Contact','Create']}
                        btnTitle="List Contact"
                        btnTo="/admin/contact">
                    </CreateContactComponent>
                </BackendLayout>
            </>
        )
    }
}
