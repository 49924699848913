import React from 'react';
import notify from "../../../util/notify";
import myHttp from "../../../util/http";
// import {SkillForm} from "./form.skill.component";
import {PortfolioForm} from "./form.portfolio.component";
import Loader from 'components/common/loader/loader';


export class CreatePortfolioComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            isSubmitting: false,
            mainCat: '',
            isLoading:true

        }

    }

    componentDidMount() {
        this.setState(pre => ({
            ...pre,
            isLoading: true
        }))

        myHttp.GET('/category',true)
            .then(res => {
                this.setState(pre => ({
                    ...pre,
                    mainCat: res.data
                }))
            })
            .catch(err => {
                console.log(err);
            })
            .finally(
                this.setState(pre => ({
                    ...pre,
                    isLoading: false
                }))
            )
    }

    addFunction = (data, files) => {
        // console.log('add function DATA >> ',console.log(data));
        // console.log('add function FILES >> ',console.log(files));
        this.setState({
            isSubmitting: true
        })
        // myHttp.POST( '/testimonial', data, true)

        myHttp.UPLOAD('POST', '/portfolio', data, files, 'image', true)
            // myHttp.POST('/skill', data,true)
            .then(res => {
                this.props.routeProps.history.push('/admin/portfolio/create');
                notify.showSuccess('Portfolio Created Successfully');
            })
            .catch(err => {
                notify.handleError(err);
            })
            .finally(() => {
                this.setState({
                    isSubmitting: false
                })
            })
    }


    render() {
        return (
            <>

                {
                    this.state.isLoading
                    ? <Loader/>
                    :<PortfolioForm title={this.props.title}
                                    breadcumb={this.props.breadcumb}
                                    btnTitle={this.props.btnTitle}
                                    btnTo={this.props.btnTo}
                                    submitCallback={this.addFunction}
                                    mainCat={this.state.mainCat}
                                    isSubmitting={this.state.isSubmitting}></PortfolioForm>
                }


            </>
        )
    }

}

