import React, {Component} from 'react';
import Sidebar from '../components/sidebar/backend/sidebar.component';
import Header from "../components/header/backend/header.component";
import FooterBackend from "../components/footer/backend/footer.backend";

export class BackendLayout extends Component {
    constructor(props) {
        super(props);

        this.state = ({     // Sample STATE to ignore WARNING in CONSOLE
            test:''
        })

    }

    render() {
        return (
            <>

                <div className="nav_bar">
                    <Header/>
                </div>

                <div className="side_bar">
                    <Sidebar/>
                </div>
                <div className="main">
                    {this.props.children}
                </div>
                <div className="mybackfooter">
                    <FooterBackend/>
                </div>
            </>
        )
    }
}