import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import './header.dark.component.css';
import myHttp from "../../../../util/http";
import { SocialDarkComponent } from "../../../frontend_dark/social/social.dark.component";
import ReactTypingEffect from 'react-typing-effect';

import Loader from './../../../common/loader/loader';

import Aos from "aos";
import "aos/dist/aos.css";

const imgURL = process.env.REACT_APP_IMG_URL;

export class HeaderDarkComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homes: '',
            iam: '',
            mobileDevice: false,
            isLoading: false
        }
    }

    componentDidMount() {
        Aos.init({
            duration: 1200,
            once: true
        })

        this.setState(
            (prev) => ({
                ...prev,
                isLoading: true
            })
        )

        myHttp.GET('/home')
            .then(res => {
                this.setState({
                    homes: res.data[0],
                    iam: res.data[0].iam,
                    isLoading:false
                })
               
            })
            .catch(err => {
                // console.log(err);
            })
    }


    render() {
        let content = this.state.isLoading
            ? <Loader/>
            : <>
                <h1>
                    <Link to="/" className="mr-auto">
                        <img data-aos="flip-right" height="100px"
                            width="50px"
                            src={`${imgURL}/${'sujit.png'}`}
                            alt="mylogo"
                            className="img-fluid" />
                    </Link><label data-aos="flip-down">{this.state.homes.name}</label>
                </h1>
                {/*-- Uncomment below if you prefer to use an image logo --*/}

                <h2>I am &nbsp;
                    <span>
                        <ReactTypingEffect
                            eraseSpeed={200}
                            eraseDelay={3000}
                            typingDelay={200}
                            speed={200}
                            text={this.state.iam}
                        />
                    </span> from Kathmandu Nepal</h2>

                
            </>
        return (
            <>
                {/*-- ======= Header ======= --*/}
             
                <header id="header" className={!this.props.header
                    ? ''
                    : "header header-top"}>
                    <div className="container">

                        {content}
                        <nav id="navbar"
                    className=
                    {
                        this.state.mobileDevice
                            ? "navbar-mobile"
                            : "navbar"

                    }

                >
                    <ul>
                        <li><NavLink activeClassName="active" exact to="/">Home</NavLink></li>
                        <li><NavLink activeClassName="active" exact to="/about">About</NavLink></li>
                        <li><NavLink activeClassName="active" to="/resume">Resume</NavLink></li>
                        <li><NavLink activeClassName="active" to="/services">Services</NavLink></li>
                        <li><NavLink activeClassName="active" to="/portfolio">Portfolio</NavLink>
                        </li>
                        <li><NavLink activeClassName="active" to="/contact">Contact</NavLink></li>

                    </ul>
                    <i
                        onClick={
                            () => {
                                this.setState(
                                    (prev) => ({
                                        ...prev,
                                        mobileDevice: !this.state.mobileDevice
                                    })
                                )
                            }
                        }
                        className="bi bi-list mobile-nav-toggle"></i>
                </nav>

                <SocialDarkComponent />

                    </div>
                </header>
            </>
        )
    }
}