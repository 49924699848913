import React from 'react';
import notify from "../../../util/notify";
import myHttp from "../../../util/http";
import Loader from "../../common/loader/loader";
import {PortfolioForm} from "./form.portfolio.component";


export class EditPortfolioComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
            isLoading: false,
            tableData: '',
            currently: '',
            mainCat: ''
        }
    }

    componentDidMount() {
        this.editId = this.props.routeProps.match.params['id'];
        this.setState({
            isLoading: true
        })
        myHttp.GET('/category', true)
            .then(res => {
                this.setState(pre => ({
                    ...pre,
                    mainCat: res.data
                }))
            })
            .catch(err => {
                console.log(err);
            })
            .finally(
                this.setState(pre => ({
                    ...pre,
                    isLoading: false
                }))
            )

        myHttp.GET(`/portfolio/${this.editId}`, true)
            .then(res => {
                console.log('port edit data >> ', res);
                this.setState({
                    // tableData: {...res.data}
                    tableData: {...res.data, currently: res.data.image, image: '', preview: ''},
                    currently: res.data.image
                })
            })
            .catch(err => notify.handleError(err))
            .finally(() => {
                this.setState({
                    isLoading: false,
                    isSubmitting: false
                })
            })
    }


    delImageCallBack = (portfolioId, imageIndex) => {
        // console.log(portfolioId, imageIndex);
        // eslint-disable-next-line no-restricted-globals
        let confirmation = confirm('Are you sure to del the selected image?');

        if (confirmation) {
            myHttp.PUT(`/portfolio/${portfolioId}/image/${imageIndex}`, null, true)
                .then(res => {
                    notify.showSuccess('Image Deleted Successfully');

                    const {currently} = this.state.tableData;
                    currently.splice(imageIndex, 1);
                    this.setState(
                        (preState) => ({
                            ...preState,
                            currently
                        })
                    )


                })
                .catch(err => {
                    console.log('catching err');
                    notify.handleError(err);
                })
        }
    }


    editFunction = (data, files) => {
        this.setState({
            isSubmitting: true,
            isLoading: true

        })
        myHttp.UPLOAD('PUT', `/portfolio/${this.editId}`, data, files, 'image', true)
            // myHttp.PUT(`/fact/${this.editId}`, data,true)
            .then(res => {
                // this.props.routeProps.history.push('/admin/social');
                notify.showSuccess('Portfolio updated successfully');
            })
            .catch(err => {
                notify.handleError(err);
            })
            .finally(() => {
                this.componentDidMount();

                // this.setState({
                //     isSubmitting: false,
                //     isLoading:false
                // })
            })
    }


    render() {
        let content = this.state.isLoading
            ? <>
                <Loader/>


            </>
            : <>
                {/*difference ko lagi khi lageko natra render hudaina ani load hudaina data haru form ma edit garda*/}
                <PortfolioForm title={this.props.title}

                               breadcumb={this.props.breadcumb}
                               btnTitle={this.props.btnTitle}
                               btnTo={this.props.btnTo}
                               mainCat={this.state.mainCat}
                               delImageCallBack={this.delImageCallBack}
                               submitCallback={this.editFunction}
                               tableData={this.state.tableData}
                               isLoading={this.state.isLoading}/>

            </>
        return (content)
    }

}

