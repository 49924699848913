import React from 'react';

import './submitting.component.css';


const Submitting = (props) => {
    return (
        <>
            <div className="text-center">
                <div className="loading">{props.msg}</div>
            </div>
            </>
    )
}

export {Submitting};