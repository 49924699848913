import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
import {CreateSkillComponent} from "../../../components/backend/skill/create.skill.component";

export class CreateSkillPage extends React.Component{
    constructor(){
        super();

        this.state = {
            test:''
        }
    }

    componentDidMount() {

    }

    render(){
        return(
            <>
                <BackendLayout>
                    <CreateSkillComponent
                        title="Create Skill"
                        routeProps ={this.props}
                        breadcumb={['Skill','Create']}
                        btnTitle="List Skill"
                        btnTo="/admin/skill">
                    </CreateSkillComponent>
                </BackendLayout>
            </>
        )
    }
}
