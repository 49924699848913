import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";

// import {EditSkillComponent} from "../../../components/backend/skill/edit.skill.component";
import {EditInterestComponent} from "../../../components/backend/interest/edit.interest.component";

export class EditInterestPage extends React.Component {




    render() {
        return (<>
            <BackendLayout>
                <EditInterestComponent
                    title={'Edit Interest'}
                    breadcumb={['Interest','Edit']}
                    btnTitle={'List Interest'}
                    btnTo={'/admin/interest'}
                    routeProps={this.props}>

                </EditInterestComponent>
            </BackendLayout>
        </>)
    }
}
