import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
import {ListServiceComponent} from "../../../components/backend/service/list.service.component";

export class ListServicePage extends React.Component{


    render(){
        return(
            <>

                <BackendLayout>
                    <ListServiceComponent
                        title="List Service"
                        routeProps={this.props}
                        breadcumb={['Service','List']}

                        btnTitle="Create Service"
                        btnTo="/admin/service/create">

                    </ListServiceComponent>
                </BackendLayout>

            </>
        )
    }
}
