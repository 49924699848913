import React from 'react';
import './portfolio.dark.component.css';
import myHttp from "../../../util/http";
import Loader from "components/common/loader/loader";
import { ImageModal } from "../../UI/MyModal";

import TinyLoader from './../../UI/TinyLoader';

import Aos from "aos";
import "aos/dist/aos.css";

const imageURL = process.env.REACT_APP_IMG_URL;

export class PortfolioDarkComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cats: [],
            portfolios: [],
            selectedCat: 'ALL',
            isLoading: true,
            isModalStatus: false,
            imgURL: '',
            activeCat: 'ALL'
        }
    }

    fetchCategory = e => {
        myHttp.GET('/category/active')
            .then(res => {
                this.setState(pre => ({
                    ...pre,
                    cats: res.data
                }))
            })
            .catch(err => {
                // console.log(err);
            })
            .finally(
                this.fetchPortfolios
            )
    }

    fetchPortfolios = e => {
        myHttp.GET('/portfolio/active')
            .then(res => {
                this.setState(pre => ({
                    ...pre,
                    portfolios: res.data,
                    isLoading: false
                }))
            }) 
            .catch(err => {
                // console.log(err);
            })
            .finally(
            )
    }

    componentDidMount() {
        Aos.init({
            duration: 1200,
        })

        this.fetchCategory();
        this.setState((prev) => ({
            ...prev,
            isLoading: false
        }))

    }

    filterCategory = (selectedCat) => {
        if (selectedCat === 'ALL') {
            this.setState(
                preState => ({
                    selectedCat: 'ALL',
                    activeCat: 'ALL'
                })
            )
        } else {
            this.setState(
                preState => ({
                    ...preState,
                    selectedCat,
                    activeCat: selectedCat
                })
            )
        }

    }

    closeModal = () => {
        this.setState(() => ({
            isModalStatus: false
        }))
    }

    activeModalStatus = (imgUrl) => {
        console.log('imgUrl >> ', imgUrl);
        if (imgUrl.length > 0) {

        } else {
            imgUrl = 'asdf'
        }

        this.setState(() => ({
            isModalStatus: true,
            imgUrl
        }))
    }

    showDetail = (slug) => {
        this.props.routeProps.history.push(`/portfolio/${slug}`)
    }
    render() {

        let content = this.state.isLoading
            ? <Loader />
            : <>


                <div data-aos="flip-right" className="row">
                    <div className="col-lg-12 d-flex justify-content-center">
                        <ul id="portfolio-flters">
                            <li className={this.state.activeCat === 'ALL' ? "filter-active" : ''} onClick={() => {
                                this.filterCategory('ALL')
                            }}>All
                            </li>
                            {
                                this.state.cats.map(
                                    (item, index) => (
                                        <li className={this.state.activeCat === item.name ? "filter-active" : ''} key={index + '_cat'} onClick={() => {
                                            this.filterCategory(item.name)
                                        }}>{item.name}</li>

                                    )
                                )
                            }
                        </ul>
                    </div>
                </div>

                <div data-aos="zoom-in" className="row ">

                    {
                        this.state.portfolios.filter(
                            (item) => {
                                if (this.state.selectedCat === 'ALL' && item.category_id.status === 'Active') {
                                    return true;
                                } else if (item.category_id.name === this.state.selectedCat) {
                                    return true;
                                }
                            }
                        ).map(
                            (item, index) => (
                                <div key={index + '_item'}
                                    className="col-lg-4 col-md-6 portfolio-item filter-app">
                                    <div className="portfolio-wrap">
                                        {
                                            item.image.length > 0
                                                ? <img width="100%" height="238px" src={`${imageURL}/${item.image[0]}`}

                                                    alt="portfolio" />
                                                : <img width="100%" height="238px" src={`${imageURL}/na.jpg`} alt="NA" />
                                        }

                                        <div className="portfolio-info">
                                            <h4>{item.project_title}</h4>
                                            <p>{item.category_id.name}</p>
                                            <div className="portfolio-links">

                                                {
                                                    item.image.length > 0
                                                        ? <button className="portfolio-lightbox" onClick={() => {
                                                            if (item.image.length > 0) {
                                                                this.activeModalStatus(`${imageURL}/${item.image[0]}`)
                                                            } else {
                                                                this.activeModalStatus(`${imageURL}/na.jpg`)
                                                            }
                                                        }}>
                                                            <i className="bx bx-plus"></i>
                                                        </button>
                                                        : null
                                                }


                                                <button className="portfolio-lightbox" onClick={() => {
                                                    this.showDetail(item.slug)
                                                }}>
                                                    <i className="bx bx-link"></i>
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                        )
                    }


                </div>

            </>
        return (
            <>
                {
                    this.state.isModalStatus
                        ? <ImageModal imgUrl={this.state.imgUrl} handleClose={this.closeModal} />
                        : ''
                }
                {/*-- ======= Portfolio Section ======= --*/}
                <section id="portfolio" className="portfolio section-show">
                    <div className="container">

                        <div className="section-title">
                            <h2>Portfolio</h2>
                            <p>My Works</p>
                        </div>

                        {content}

                    </div>
                </section>
            </>
        )
    }
}

