import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
import {ListCategoryComponent} from "../../../components/backend/category/list.category.component";

export class ListCategoryPage extends React.Component{


    render(){
        return(
            <>

                <BackendLayout>
                    <ListCategoryComponent
                        title="List Category"
                        routeProps={this.props}
                        breadcumb={['Category','List']}

                        btnTitle="Create Category"
                        btnTo="/admin/category/create">

                    </ListCategoryComponent>
                </BackendLayout>

            </>
        )
    }
}
