import React from 'react';
import {UserForm} from 'components/backend/user/form.user.component';
import notify from "../../../util/notify";
import myHttp from "../../../util/http";
// import Loader from "../../common/loader/loader";


export class EditUserComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
            isLoading: false,
            user: ''
        }
    }


    componentDidMount() {
        this.userId = this.props.routeProps.match.params['id'];
        this.setState({
            isLoading: true
        })
        myHttp.GET(`/user/${this.userId}`, true,)
            .then(res => {
                this.setState({
                    user: res.data
                })
            })
            .catch(err => notify.handleError(err))
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    editFunction = (data, files) => {
        this.setState({
            isSubmitting: false
        })
        myHttp.UPLOAD('PUT', `/user/${this.userId}`, data, files, 'avatar', true)
            // myHttp.POST('/auth/register', data,true)
            .then(res => {
                this.props.routeProps.history.push('/user');
                notify.showSuccess('User updated successfully');
            })
            .catch(err => {
                notify.handleError(err);
            })
            .finally(() => {
                this.setState({
                    isSubmitting: false
                })
            })
    }


    render() {
        let content = this.state.isLoading
            ? <>
                <UserForm title={this.props.title}
                          breadcumb={this.props.breadcumb}
                          btnTitle={this.props.btnTitle}
                          btnTo={this.props.btnTo}
                          submitCallback={this.editFunction}
                          userx={this.state.user}
                          isLoading={this.state.isLoading}>

                </UserForm>

            </>
            : <>       {/*difference ko lagi khi lageko natra render hudaina ani load hudaina data haru form ma edit garda*/}
                <UserForm title={this.props.title}
                          breadcumb={this.props.breadcumb}
                          btnTitle={this.props.btnTitle}
                          btnTo={this.props.btnTo}
                          submitCallback={this.editFunction}
                          userx={this.state.user}
                          isLoading={this.state.isLoading}>

                </UserForm>
            </>
        return (content)
    }

}

