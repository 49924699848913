import React from 'react';
import {TestimonialForm} from 'components/backend/testimonial/form.testimonial.component';
import notify from "../../../util/notify";
import myHttp from "../../../util/http";


export class CreateTestimonialComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            isSubmitting: false,

        }

    }

    addFunction = (data, files) => {
        console.log('add function testim >> ',console.log(data,files));
        this.setState({
            isSubmitting: true
        })
        // myHttp.POST( '/testimonial', data, true)

        myHttp.UPLOAD('POST', '/testimonial', data, files, 'avatar', true)
            // myHttp.POST('/auth/register', data,true)
            .then(res => {
                this.props.routeProps.history.push('/admin/testimonial/create');
                notify.showSuccess('Testimonial created successfully');
            })
            .catch(err => {
                notify.handleError(err);
            })
            .finally(() => {
                this.setState({
                    isSubmitting: true
                })
            })
    }



    render() {
        return (
            <>

                <TestimonialForm title={this.props.title}
                          breadcumb={this.props.breadcumb}
                          btnTitle={this.props.btnTitle}
                          btnTo={this.props.btnTo}
                          submitCallback={this.addFunction}
                          isSubmitting={this.state.isSubmitting}></TestimonialForm>

            </>
        )
    }

}

