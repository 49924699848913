import React from "react";
import {CreateEducationComponent} from "../../../../components/backend/education/create.education.component";
import {BackendLayout} from "../../../../layouts/backend.layout";

export class CreateEducationPage extends React.Component{

    render(){
        return(
            <>
                <BackendLayout>
                    <CreateEducationComponent
                        title="Create Education"
                        routeProps ={this.props}
                        breadcumb={['Resume','Education','Create']}
                        btnTitle="List Education"
                        btnTo="/admin/education">
                    </CreateEducationComponent>
                </BackendLayout>
            </>
        )
    }
}
