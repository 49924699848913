import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
// import {CreateUserComponent} from "../../../components/backend/user/create.user.component";
// import {EditUserComponent} from "../../../components/backend/user/edit.user.component";
// import myHttp from "../../../util/http";
// import notify from "../../../util/notify";
// import {UserForm} from "../../../components/backend/user/form.user.component";
// import Loader from 'components/common/loader/loader';
// import {EditTestimonialComponent} from "../../../components/backend/testimonial/edit.testimonial.component";
import {EditSocialComponent} from "../../../components/backend/social/edit.social.component";

export class EditSocialPage extends React.Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
        }
    }


    // componentDidMount() {
    //     this.testimonialId = this.props.match.params['id'];
    //     this.setState({
    //         isLoading: true
    //     })
    //     myHttp.GET(`/testimonial/${this.testimonialId}`, true,)
    //         .then(res => {
    //             this.setState({
    //                 testimonial: res.data
    //             })
    //         })
    //         .catch(err => notify.handleError(err))
    //         .finally(() => {
    //             this.setState({
    //                 isLoading: false
    //             })
    //         })
    // }


    render() {
        return (<>
            <BackendLayout>
                <EditSocialComponent
                    title={'Edit Social'}
                    breadcumb={['Social','Edit']}
                    btnTitle={'List Social'}
                    btnTo={'/admin/social'}
                    routeProps={this.props}>

                </EditSocialComponent>
            </BackendLayout>
        </>)
    }
}
