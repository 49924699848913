import moment from 'moment';

function formatDate(date) {
    return moment(date).format('YYYY-MM-DD ddd');
}

function formatTime(date) {
    return moment(date).format('hh:mm a');
}

function relativeTime(date) {

    return moment(date).calendar();
}


const dateUtil = {
    formatDate,
    formatTime,
    relativeTime
}


export default dateUtil;