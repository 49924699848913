import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
import {ListPortfolioComponent} from "../../../components/backend/portfolio/list.portfolio.component";
// import {ListTestimonialComponent} from "../../../components/backend/testimonial/list.testimonial.component";
// import {ListSocialComponent} from "../../../components/backend/social/list.social.component";
// import {ListFactComponent} from "../../../components/backend/fact/list.fact.component";
// import {ListSkillComponent} from "../../../components/backend/skill/list.skill.component";

export class ListPortfolioPage extends React.Component{
    constructor(){
        super();

        this.state = {
            test:''
        }
    }

    render(){
        return(
            <>

                <BackendLayout>
                    <ListPortfolioComponent
                        title="List Portfolio"
                        routeProps={this.props}
                        breadcumb={['Portfolio','List']}

                        btnTitle="Create Portfolio"
                        btnTo="/admin/portfolio/create">

                    </ListPortfolioComponent>
                </BackendLayout>

            </>
        )
    }
}
