import React from 'react';

import './TinyLoader.css';


const TinyLoader = (props) => {
    return (
        <>
            <div>
                <div className="loading">{props.msg}</div>
            </div>
            </>
    )
}

export {TinyLoader};