import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
// import {ListTestimonialComponent} from "../../../components/backend/testimonial/list.testimonial.component";
import {ListSocialComponent} from "../../../components/backend/social/list.social.component";

export class ListSocialPage extends React.Component{
    constructor(){
        super();

        this.state = {
            test:''
        }
    }

    render(){
        return(
            <>

                <BackendLayout>
                    <ListSocialComponent
                        title="List Social"
                        routeProps={this.props}
                        breadcumb={['Social','List']}

                        btnTitle="Create Social"
                        btnTo="/admin/social/create">

                    </ListSocialComponent>
                </BackendLayout>

            </>
        )
    }
}
