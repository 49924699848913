import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
// import {CreateTestimonialComponent} from "../../../components/backend/testimonial/create.testimonial.component";
import {CreateHomeComponent} from "../../../components/backend/home/create.home.component";

export class CreateHomePage extends React.Component{
    constructor(){
        super();

        this.state = {
            test:''
        }
    }

    componentDidMount() {

    }

    render(){
        return(
            <>
                <BackendLayout>
                    <CreateHomeComponent
                        title="Create Home"
                        routeProps ={this.props}
                        breadcumb={['Home','Create']}
                        btnTitle="List Home"
                        btnTo="/admin/home">
                    </CreateHomeComponent>
                </BackendLayout>
            </>
        )
    }
}
