import React from 'react'
import myHttp from "../../../util/http";
import notify from "../../../util/notify";
import Loader from "components/common/loader/loader";
import dateUtil from "util/dateUtil";
import {Link} from 'react-router-dom';
import './list.skill.component.css';
import {ImageModal} from "./../../../components/UI/MyModal";

const imageUrl = process.env.REACT_APP_IMG_URL;


export class ListSkillComponent extends React.Component {
    constructor() {
        super();
        this.state = {

            isLoading: false,
            tableData: [],
            searchTerm: '',

            imgUrl:'',
            isModalStatus:false,

            // showModal: false
        }
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        })

        myHttp.GET('/skill', true)
            .then(res => {
                this.setState({
                    tableData: res.data,
                })
            })
            .catch(err => {
                notify.handleError(err)
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    edit = (id) => {
        this.props.routeProps.history.push(`/admin/skill/${id}/edit`)
    }


    remove(id, index) {
        // eslint-disable-next-line no-restricted-globals
        let confirmation = confirm("Are you sure to remove the selection?");
        if (confirmation) {
            myHttp.DELETE(`/skill/${id}`, true)
                .then(res => {
                    notify.showSuccess('Deleted Successfully');
                    const {tableData} = this.state;
                    tableData.splice(index, 1);
                    this.setState({
                        tableData
                    })
                })
                .catch(err => {
                    notify.handleError(err);
                })
        }
    }

    handleSearch = (e) => {
        this.setState((prev) => ({
            searchTerm: e.target.value
        }), () => {
        })
    }

    closeModal = () => {
        this.setState(()=> ({
            isModalStatus:false
        }))
    }

    activeModalStatus = (imgUrl) => {
        this.setState(()=>({
            isModalStatus:true,
            imgUrl
        }))
    }


    render() {
        let content = this.state.isLoading
            ? <>
                <tr>
                    <td colSpan="12"><Loader/></td>
                </tr>


            </>
            : <>

                {this.state.tableData.filter(obj => {
                    // console.log('complete data >> ', obj);
                    if (this.state.searchTerm === "") {
                        return obj;
                    } else if (
                        obj.subject.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
                        obj.percentage.toString().includes(this.state.searchTerm) ||
                        obj.status.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
                        (obj.logo && obj.logo.toLowerCase().includes(this.state.searchTerm.toLowerCase())) ||
                        obj.rank.toString().includes(this.state.searchTerm)

                    ) {
                        return obj;
                    }


                }).map((row, index) => (
                    <tr key={row._id}>
                        <th scope="row">{index + 1}</th>
                        <td>{row.type}</td>
                        <td>
                            {row.logo} &nbsp; <i style={{color:"blue"}} className={row.logo}></i> <br/>
                            {


                                row.image
                                    ? <button  onClick={()=>{this.activeModalStatus(`${imageUrl}/${row.image}`)}}><img
                                        src={`${imageUrl}/${row.image}`} height="60px" alt="Client"/></button>
                                    : 'NA'
                            }

                        </td>


                        <td>
                            {
                                row.status === 'Active'
                                    ? <label className="bg-info bg-gradient">{row.status}</label>
                                    : <label className="bg-danger bg-gradient">{row.status}</label>

                            }
                        </td>
                        <td>
                            {row.rank}
                        </td>


                        <td>
                            {row.level}
                        </td>



                        <td>
                            {dateUtil.formatDate(row.createdAt)} <br/>
                            {dateUtil.formatTime(row.createdAt)}
                        </td>
                        <td>
                            <button className="btn btn-info" onClick={() => this.edit(row._id)}>Edit</button>
                            <button className="btn btn-danger" onClick={() => this.remove(row._id, index)}>Del
                            </button>
                        </td>
                    </tr>
                ))}


            </>
        return (<>
            {
                this.state.isModalStatus
                ?   <ImageModal imgUrl={this.state.imgUrl} handleClose={this.closeModal}/>
                : ''
            }

            <div className="breadcrumb d-flex justify-content-between align-items-center">
                <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item"><Link to="#">{this.props.breadcumb[0]}</Link></li>
                    <li className="breadcrumb-item active">{this.props.breadcumb[1]}</li>
                    {this.props.breadcumb[2]
                        ? <li className="breadcrumb-item active">{this.props.breadcumb[2]}</li>
                        : ''
                    }

                </ol>
                <h4 className="text-center mytitle">{this.props.title}</h4>
                <Link to={this.props.btnTo} className="btn btn-sm btn-success">{this.props.btnTitle}</Link>
            </div>

            <div>
                <div className="form-group">
                    <input className="form-control" onChange={this.handleSearch} type="text"
                           placeholder="Search Data | Searching column one at a time without white space (Title|Logo|Status|Rank|Percentage)"/>
                </div>
                <table className="table">
                    <thead className="thead-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Subject</th>
                        <th scope="col">Logo/Image</th>
                        <th scope="col">Status</th>
                        <th scope="col">Rank</th>
                        <th scope="col">Percentage</th>
                        <th scope="col">Created On</th>
                        <th scope="col">Actions</th>

                    </tr>
                    </thead>
                    <tbody>
                    {content}
                    {/*<ImageModal />*/}
                    </tbody>
                </table>


            </div>


        </>)
    }

    // showModalRef = ({showModal}) => {
    //     this.showModal = showModal;
    //     console.log('this is show image');
    //     // this.props.routeProps.history.push(`/testimonial/${id}`)
    //     // <ImageModal visible={true} imgUrl="http://localhost:8888/images/1624035021597-waterfall.jpg"/>
    // }


    // onClick = (param) => {
    //     console.log(param);
    //     this.showModal();
    //     // this.props.routeProps.history.push(`/testimonial/${id}`)
    // }

}


