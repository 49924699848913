import React from "react";
import {BackendLayout} from "../../../../layouts/backend.layout";
import {ListTrainingComponent} from "../../../../components/backend/training/list.training.component";
export class ListTrainingPage extends React.Component{


    render(){
        return(
            <>

                <BackendLayout>
                    <ListTrainingComponent
                        title="List Training"
                        routeProps={this.props}
                        breadcumb={['Training','List']}

                        btnTitle="Create Training"
                        btnTo="/admin/training/create">

                    </ListTrainingComponent>
                </BackendLayout>

            </>
        )
    }
}
