import React from "react";
import {BackendLayout} from "./../../../../layouts/backend.layout";

// import {EditEducationComponent} from "../../../../components/backend/education/edit.education.component";
import {EditTrainingComponent} from "../../../../components/backend/training/edit.training.component";

export class EditTrainingPage extends React.Component {




    render() {
        return (<>
            <BackendLayout>
                <EditTrainingComponent
                    title={'Edit Training'}
                    breadcumb={['Resume','Training','Edit']}
                    btnTitle={'List Training'}
                    btnTo={'/admin/training'}
                    routeProps={this.props}>

                </EditTrainingComponent>
            </BackendLayout>
        </>)
    }
}
