import React from "react";

import {Link} from 'react-router-dom';

import Loader from 'components/common/loader/loader';

const imgUrl = process.env.REACT_APP_IMG_URL;
const defaultForm = {
    name: '',
    email: '',
    password: '',
    cpassword: '',
    role: '',
    status: '',
    avatar: '',
    // images: ''
}

export class UserForm extends React.Component {
    constructor() {
        super();
        this.state = {
            data: {
                ...defaultForm
            },
            error: {
                ...defaultForm
            },
            isValidForm: false,
            isSubmitting: false,
            pure: {
                name: true,
                email: true,
                password: true,
                cpassword: true,
                role: true,
                status: true,
                avatar: false
            },
            filesToUpload: [],
            file: null
        }
    }

    componentDidMount() {
        if (this.props.userx) {
            this.setState({
                data: {
                    ...defaultForm,
                    ...this.props.userx
                }
            }, () => {
                let purex = {};
                let errx = {};
                var reqField = ['name', 'email', 'role', 'status', 'avatar'];
                for (let [key, value] of Object.entries(this.state.data)) {
                    console.log(value.length);
                    if (value.length >= 1) {
                        if (reqField.includes('password') || reqField.includes('cpassword')) {
                            purex[key] = false;
                        } else if (reqField.includes(key)) {
                            purex[key] = false;
                            errx[key] = '';
                        }

                        // console.log(key + ' is not empty ', purex[key]);
                    } else {
                        // console.log(key + ' is empty');
                    }

                }
                this.setState((preState) => ({
                    pure: {
                        ...preState.pure,
                        ...purex
                    },
                    error: {
                        ...preState.error,
                        ...errx

                    }
                }))
            })


        }

    }

    handleChange = (e) => {
        console.log(e.target);
        const {name, value, type, files} = e.target;   // destruct could be => name, value, type, files, checked etc...

        if (type === 'file') {
            const {filesToUpload} = this.state;
            filesToUpload.push(files[0]);
            this.setState((preState) => ({
                ...preState.data,
                filesToUpload,
                file: URL.createObjectURL(e.target.files[0])
            }))

            this.setState(preState => ({
                // data: {
                //     ...preState.data,
                //     [name]: value
                // },

                pure: {
                    ...preState.pure,
                    [name]: false
                }
            }), () => {
                this.validateForm(name);
            });

        }else{
            this.setState(preState => ({
                data: {
                    ...preState.data,
                    [name]: value
                },

                pure: {
                    ...preState.pure,
                    [name]: false
                }
            }), () => {
                this.validateForm(name);
            });
        }



    }


    validateForm(fieldName) {

        let errMsg;
        switch (fieldName) {
            case 'name':
                errMsg = this.state.data[fieldName]
                    ? ''
                    : 'Fullname is required'
                break;
            case 'email':
                errMsg = this.state.data[fieldName]
                    ? this.state.data[fieldName].includes('@') && this.state.data[fieldName].includes('.com')
                        ? ''
                        : 'Invalid Email'
                    : 'Email field is required'
                break;
            case 'password':
                errMsg = this.state.data[fieldName]
                    ? this.state.data[fieldName].length > 6
                        ? this.state.data[fieldName] === this.state.data.cpassword
                            ? ''
                            : 'Password don\'t matched'
                        : 'Weak Password'
                    : 'Password is required'
                break

            case 'cpassword':
                fieldName = 'password';
                errMsg = this.state.data[fieldName]
                    ? this.state.data.cpassword.length > 6
                        ? this.state.data[fieldName] === this.state.data.cpassword
                            ? ''
                            : 'Password don\'t matched'
                        : 'Weak Password'
                    : 'Password is required'
                break

            case 'role':
                const roleArray = ['Admin', 'User'];
                errMsg = roleArray.includes(this.state.data[fieldName])
                    ? ''
                    : 'Role is required'
                break;

            case 'status':
                const statusArray = ['Active', 'Inactive'];
                errMsg = statusArray.includes(this.state.data[fieldName])
                    ? ''
                    : 'Status is required'
                break;

            case 'avatar':
                errMsg = this.state.avatar
                    ? ''
                    : ''
                break;


            default:
                break;
        }
        this.setState(preState => ({
            error: {
                ...preState.error,
                [fieldName]: errMsg
            }
        }), () => {
            const {error, pure} = this.state;
            let err = Object.values(error).filter(item => item);
            let dirty = Object.values(pure).filter(item => item);
            // console.log(err);
            this.setState({
                isValidForm: err.length === 0 && dirty.length === 0
            })
        })
    }

    verifiedPassword() {
        console.log('111errr >>> ', this.state);
        this.setState(preState => ({
            error: {
                ...preState.error,
                password: '',
                cpassword: '',
            }
        }), () => {
            console.log('222errr >>> ', this.state);
            return true;
        })
    }


    handleSubmit = e => {
        console.log(this.state);
        e.preventDefault();
        console.log(this.state.data);
        this.setState({isSubmitting: true});
        if (this.state.filesToUpload.length === 0) {
            this.props.submitCallback(this.state.data, null);
        } else {
            this.props.submitCallback(this.state.data, this.state.filesToUpload);
        }
    }


    render() {
        console.log('render() 1 => formuser.component', this.state);

        let btn = this.props.isSubmitting
            ? <button type="submit" disabled className="btn btn-secondary btn-lg btn-block">Submitting...</button>
            : <button type="submit" disabled={!this.state.isValidForm}
                      className="btn btn-primary btn-lg btn-block">Submit</button>

        let content = this.props.isLoading
            ? <>
                <Loader/>
            </>
            : <form className="form-padding" onSubmit={this.handleSubmit}>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label htmlFor="inputEmail4">Name*</label>
                        <input name="name" value={this.state.data.name} onChange={this.handleChange} type="text"
                               className={this.state.error.name ? 'form-control is-invalid' : 'form-control'}
                               id="inputName"
                               placeholder="Name"/>
                    </div>
                    <p className="error">{this.state.error.name}</p>
                </div>

                <div className="form-row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="inputEmail4">Email*</label>
                            <input name="email" value={this.state.data.email} onChange={this.handleChange}
                                   type="email"
                                   className={this.state.error.email ? 'form-control is-invalid' : 'form-control'}
                                   id="inputEmail4"
                                   placeholder="Email"/>
                        </div>
                        <p className="error">{this.state.error.email}</p>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="inputPassword4">Password</label>
                            <input name="password" onChange={this.handleChange}
                                   type="password"
                                   className={this.state.error.password ? 'form-control is-invalid' : 'form-control'}
                                   id="inputPassword" placeholder="Password"/>
                        </div>
                        <p className="error">{this.state.error.password}</p>
                    </div>

                    <div className="form-group col-md-4">
                        <label htmlFor="inputPassword4">Confirm Password</label>
                        <input name="cpassword" onChange={this.handleChange} type="password"
                               className={this.state.error.password ? 'form-control is-invalid' : 'form-control'}
                               id="inputCPassword" placeholder="Password"/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="inputState">Role</label>
                            <select onChange={this.handleChange} name="role" id="inputRole"
                                    className={this.state.error.role ? 'form-control is-invalid' : 'form-control'}>
                                <option value="{this.state.data.role}">{this.state.data.role} </option>
                                <option value="Admin">Admin</option>
                                <option value="User">User</option>
                            </select>
                        </div>
                        <p className="error">{this.state.error.role}</p>

                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="inputStatus">Status</label>
                            <select onChange={this.handleChange} name="status" id="inputState"
                                    className={this.state.error.status ? 'form-control is-invalid' : 'form-control'}>
                                <option value="{this.state.data.status}">{this.state.data.status}</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </select>
                        </div>
                        <p className="error">{this.state.error.status}</p>

                    </div>

                </div>


                <div className="form-group">
                    <p>Avatar&nbsp;
                        {
                            this.state.data.avatar
                                ? <img src={`${imgUrl}/${this.state.data.avatar}`} alt="Avatar Pic" width="200px"
                                       height="150px"/>
                                : <label> => Don't Exist</label>
                        }
                    </p>
                    <p>Currently Selection&nbsp;
                        {
                            this.state.file
                                ? <img src={this.state.file} alt="Avatar Pic" width="200px"
                                       height="150px"/>
                                : <label> => No selection yet</label>
                        }
                    </p>

                    <div className="custom-file">
                        <input onChange={this.handleChange} type="file" name="avatar"

                               className={this.state.error.avatar ? 'custome-file-input is-invalid' : 'custom-file-input'}
                               id="validwatedCustomFile"/>
                        <label className="custom-file-label" htmlFor="valfidatedCustomFile">Choose
                            file...</label>
                        <div className="invalid-feedback">Avatar of User Not Selected Yet</div>
                    </div>
                </div>
                {btn}
            </form>
        return (
            <>
                <div className="breadcrumb d-flex justify-content-between align-items-center">
                    <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item"><Link to="#">{this.props.breadcumb[0]}</Link></li>
                        <li className="breadcrumb-item active">{this.props.breadcumb[1]}</li>
                    </ol>
                    <h4 className="text-center mytitle">{this.props.title}</h4>
                    <Link to={this.props.btnTo} className="btn btn-sm btn-success">{this.props.btnTitle}</Link>
                </div>

                {content}
            </>
        )
    }
}

