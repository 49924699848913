import { toast } from 'react-toastify';

function showSuccess(msg) {
    return toast.success(msg);
}

function showInfo(msg) {
    return toast.info(msg);
}

function showWarning(msg) {
    return toast.warning(msg);
}

function showError(msg) {
    return toast.error(msg);
}

function handleError(error) {
    // console.log('handle error', error.message);
    // debugger;

    // let parsed;
    return showError( error.response.data.msg || error.message);
    // console.log('ClientSide Web Console Err >> ', parsed = JSON.parse(error));

    // if(parsed.msg){
    //     return showError(parsed.msg);
    // }
    // console.log(error);
    // let errMsg;
    // if(error.response && !error.response.data){
    //     showError(error.response.msg);
    // }
    // else if (error.response.data.msg.message) {    // Default Express error message
    //     errMsg = error.response.data.msg.message;
    // } else if (error.response.data.msg) {  // my customized error message
    //     errMsg = error.response.data.msg;
    // }
    // showError(errMsg);

    // if (error.response.data.status === 401) {
    //     showError("Auth Failed due to invalid token");
    // } else {
    //     // const err = error.data;



    //     if (error.response.data.msg.message) {    // Default Express error message
    //         errMsg = error.response.data.msg.message;
    //     } else if (error.response.data.msg) {  // my customized error message
    //         errMsg = error.response.data.msg;
    //     }
    //     showError(errMsg);
    // }

}

const notify = {
    showSuccess,
    handleError,
    showInfo,
    showWarning
}

export default notify;