import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";

import {EditCategoryComponent} from "../../../components/backend/category/edit.category.component";

export class EditCategoryPage extends React.Component {




    render() {
        return (<>
            <BackendLayout>
                <EditCategoryComponent
                    title={'Edit Category'}
                    breadcumb={['Category','Edit']}
                    btnTitle={'List Category'}
                    btnTo={'/admin/category'}
                    routeProps={this.props}>

                </EditCategoryComponent>
            </BackendLayout>
        </>)
    }
}
