import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";

// import {EditSkillComponent} from "../../../components/backend/skill/edit.skill.component";
import {EditPortfolioComponent} from "../../../components/backend/portfolio/edit.portfolio.component";

export class EditPortfolioPage extends React.Component {




    render() {
        return (<>
            <BackendLayout>
                <EditPortfolioComponent
                    title={'Edit Portfolio'}
                    breadcumb={['Portfolio','Edit']}
                    btnTitle={'List Portfolio'}
                    btnTo={'/admin/portfolio'}
                    routeProps={this.props}>

                </EditPortfolioComponent>
            </BackendLayout>
        </>)
    }
}
