import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
import {CreateInterestComponent} from "../../../components/backend/interest/create.interest.component";

export class CreateInterestPage extends React.Component{

    render(){
        return(
            <>
                <BackendLayout>
                    <CreateInterestComponent
                        title="Create Interest"
                        routeProps ={this.props}
                        breadcumb={['Interest','Create']}
                        btnTitle="List Interest"
                        btnTo="/admin/interest">
                    </CreateInterestComponent>
                </BackendLayout>
            </>
        )
    }
}
