import axios from "axios";

const BaseURL = process.env.REACT_APP_BASE_URL;
const http = axios.create({
    baseURL: BaseURL,
    responseType: 'json'
})

const getHeaders = (isSecure) => {
    let options = {
        'Content-Type': 'application/json'
    }
    if (isSecure) {
        options['Authorization'] = localStorage.getItem('token')
    }
    return options;
}

function GET(url, isSecure = false, params = {}) {
    return http.get(url, {
        headers: getHeaders(isSecure),
        params
    });
}

function POST(url, data, isSecure = false, params = {}) {
    // console.log(url);
    return http.post(url, data,
        {
            headers: getHeaders(isSecure),
            params
        });
}

function DELETE(url, isSecure = false, params = {}) {
    return http.delete(url,
        {
            headers: getHeaders(isSecure),
            params
        });
}

function PUT(url, data, isSecure = false, params = {}) {
    return http.put(url, data, {
        headers: getHeaders(isSecure),
        params
    })
}


function UPLOAD(method, url, data, files,keyName,isSecure=false, params = {}) {
    return new Promise((resolve, reject) => {
        // send request to server using xml http request
        const xhr = new XMLHttpRequest();
        const formData = new FormData();
        
        // console.log('files http web >>',files);
        // if (files && files.length) {
        //     formData.append(keyName, files[0], files[0].name);
        // }
        if (files && files.length) {
            files.forEach(function(file,index){
                formData.append(keyName, file, file.name);
            })
        }
        // add each data in formdata
        for (let key in data) {
            formData.append(key, data[key]);
        }
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {

                    resolve(xhr.response)
                } else {
                    reject(xhr.response)
                }
            }
        }

        xhr.open(method, `${BaseURL}${url}?token=${localStorage.getItem('token')}`, true);
        xhr.send(formData);
    })


}

const myHttp = {
    GET,
    POST,
    DELETE,
    PUT,
    UPLOAD
}

export default myHttp;