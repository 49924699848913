import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
import {CreateTestimonialComponent} from "../../../components/backend/testimonial/create.testimonial.component";

export class CreateTestimonialPage extends React.Component{
    constructor(){
        super();

        this.state = {
            test:''
        }
    }

    componentDidMount() {

    }

    render(){
        return(
            <>
                <BackendLayout>
                    <CreateTestimonialComponent
                        title="Create Testimonial"
                        routeProps ={this.props}
                        breadcumb={['Testimonial','Create']}
                        btnTitle="List Testimonial"
                        btnTo="/admin/testimonial">
                    </CreateTestimonialComponent>
                </BackendLayout>
            </>
        )
    }
}
