import React from "react";
import {BackendLayout} from "./../../../../layouts/backend.layout";

// import {EditEducationComponent} from "../../../../components/backend/education/edit.education.component";
// import {EditTrainingComponent} from "../../../../components/backend/training/edit.training.component";
import {EditExpComponent} from "../../../../components/backend/exp_curr/edit.exp.component";

export class EditExpPage extends React.Component {




    render() {
        return (<>
            <BackendLayout>
                <EditExpComponent
                    title={'Edit Experiences/Currently'}
                    breadcumb={['Resume','Experiences/Currently','Edit']}
                    btnTitle={'List Experiences/Currently'}
                    btnTo={'/admin/exp_curr'}
                    routeProps={this.props}>

                </EditExpComponent>
            </BackendLayout>
        </>)
    }
}
