import React from 'react';
import notify from "../../../util/notify";
import myHttp from "../../../util/http";
import {SummaryForm} from "./form.summary.component";
// import {TestimonialForm} from "../testimonial/form.testimonial.component";


export class CreateSummaryComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            isSubmitting: false,
            tableData: '',
            editComponent: true,
            editId: '',
            isLoading:''


        }

    }

    // Exist lastest one data or not
    hasOldData = () => {
        this.setState({
            isLoading: true
        })
        myHttp.GET(`/summary`,true)
            .then(res => {
                if (res.data.length > 0) {  // EDIT: when data exist
                    console.log('exist data >> ',res);
                    this.setState({
                        editId: res.data[0]._id,
                        tableData: res.data[0],
                        editComponent: true
                    })
                } else {
                    // Nothing to do just execute like normal process
                    this.setState({
                        editComponent: false,
                        // isLoading:false TODO:verify with new create data
                    })
                }

            })
            .catch(err => notify.handleError(err))
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    componentDidMount = () => {
        this.hasOldData();
    }

    addFunction = (data, files) => {
        this.setState({
            isSubmitting: true,
            isLoading: true,
        })

        // myHttp.UPLOAD('POST', '/about', data, files, 'image', true)
        myHttp.POST('/summary',data,true)
            .then(res => {
                this.props.routeProps.history.push('/admin/summary');
                notify.showSuccess('Summary created successfully');
            })
            .catch(err => {
                notify.handleError(err);
            })
            .finally(this.componentDidMount)
    }

    editFunction = (data, files) => {
        // console.log('edit function  >> ',data);
        this.setState({
            isLoading: true,
            isSubmitting: true,
            editId:data._id
        })
        // myHttp.UPLOAD('PUT', `/summary/${this.state.editId}`, data, files, 'image', true)
        myHttp.PUT('/summary/'+this.state.editId,data,true)
            .then(res => {
                // console.log('final edit wala main data >>> ',res.iam);
                    this.setState((prev)=> ({
                        // editId: res._id,
                        tableData: res.data,
                        editComponent: true
                    }))
            })
            .catch(err => {
                notify.handleError(err);
            })
            .finally(() => {
                this.setState(({
                        // isSubmitting: false,
                    }), () => {
                        this.hasOldData();
                        notify.showSuccess('Summary updated successfully');
                    }
                )
            })
    }


    render() {
        return (
            <>
                {this.state.editComponent
                    ?
                    <>
                        {/*{console.log('create home compo')}*/}
                        <SummaryForm title='Update Summary'
                                  breadcumb={['Resume','Summary', 'Update']}
                                  editComponent={true}
                            // btnTitle={this.props.btnTitle}
                            // btnTo={this.props.btnTo}
                                  submitCallback={this.editFunction}
                                  tableData={this.state.tableData}
                                  isLoading={this.state.isLoading}/>
                    </>
                    :
                    <SummaryForm title={this.props.title}
                              breadcumb={this.props.breadcumb}
                              editComponent={false}
                        // btnTitle={this.props.btnTitle}
                        // btnTo={this.props.btnTo}
                              submitCallback={this.addFunction}
                              isSubmitting={this.state.isSubmitting}></SummaryForm>
                }

            </>
        )
    }

}

