import React from 'react';
import notify from "../../../util/notify";
import myHttp from "../../../util/http";
import {FactForm} from "./form.fact.component";


export class CreateFactComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            isSubmitting: false,

        }

    }

    addFunction = (data, files) => {
        // console.log('add function testim >> ',console.log(data,files));
        this.setState({
            isSubmitting: true
        })
        // myHttp.POST( '/testimonial', data, true)

        // myHttp.UPLOAD('POST', '/testimonial', data, files, 'avatar', true)
            myHttp.POST('/fact', data,true)
            .then(res => {
                this.props.routeProps.history.push('/admin/fact/create');
                notify.showSuccess('Facts Created Successfully');
            })
            .catch(err => {
                notify.handleError(err);
            })
            .finally(() => {
                this.setState({
                    isSubmitting: false
                })
            })
    }



    render() {
        return (
            <>

                <FactForm title={this.props.title}
                          breadcumb={this.props.breadcumb}
                          btnTitle={this.props.btnTitle}
                          btnTo={this.props.btnTo}
                          submitCallback={this.addFunction}
                          isSubmitting={this.state.isSubmitting}></FactForm>

            </>
        )
    }

}

