import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
import {ListInterestComponent} from "../../../components/backend/interest/list.interest.component";

export class ListInterestPage extends React.Component{
    constructor(){
        super();

        this.state = {
            test:''
        }
    }

    render(){
        return(
            <>

                <BackendLayout>
                    <ListInterestComponent
                        title="List Interest"
                        routeProps={this.props}
                        breadcumb={['Interest','List']}

                        btnTitle="Create Interest"
                        btnTo="/admin/interest/create">

                    </ListInterestComponent>
                </BackendLayout>

            </>
        )
    }
}
