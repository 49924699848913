import React from "react";

import { PortfolioDarkComponent } from "../../components/frontend_dark/portfolio/portfolio.dark.component";

import useDocumentTitle from './../../components/useDocumentTitle';

// export default class PortfolioDarkPage extends React.Component {


//     render() {
//         return (
//             <>

//                     <PortfolioDarkComponent routeProps={this.props}/>



//             </>
//         )
//     }

// }


export default function PortfolioDarkPage(props) {
    useDocumentTitle('Portofilo 💻 | Web Developer | Portfolio | Sujit Dahal 🧍')
    return (
        <>

            <PortfolioDarkComponent routeProps={props} />



        </>
    )


}