import React from 'react';
import notify from "../../../util/notify";
import myHttp from "../../../util/http";
import {HomeForm} from "./form.home.component";
// import {TestimonialForm} from "../testimonial/form.testimonial.component";


export class CreateHomeComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            isSubmitting: false,
            tableData: '',
            editComponent: true,
            editId: '',
            isLoading:''


        }

    }

    // Exist lastest one data or not
    hasOldData = () => {
        this.setState({
            isLoading: true
        })
        myHttp.GET(`/home`)
            .then(res => {
                if (res.data.length > 0) {  // EDIT: when data exist
                    this.setState({
                        editId: res.data[0]._id,
                        tableData: {...res.data[0], currently: res.data[0].image, image: '',preview:''},
                        editComponent: true
                    })
                } else {
                    // Nothing to do just execute like normal process
                    this.setState({
                        editComponent: false,
                        // isLoading:false TODO:verify with new create data
                    })
                }

            })
            .catch(err => notify.handleError(err))
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    componentDidMount = () => {
        this.hasOldData();
    }

    addFunction = (data, files) => {
        this.setState({
            isSubmitting: true,
            isLoading: true,
        })

        myHttp.UPLOAD('POST', '/home', data, files, 'image', true)
            .then(res => {
                this.props.routeProps.history.push('/admin/home');
                notify.showSuccess('Home created successfully');
            })
            .catch(err => {
                notify.handleError(err);
            })
            .finally(() => {
                this.setState(({
                        isSubmitting: true
                    }),
                    this.componentDidMount)
            })
    }

    editFunction = (data, files) => {
        // console.log('edit function  >> ',data);
        this.setState({
            isLoading: true,
            isSubmitting: true,
            editId:data._id
        })
        myHttp.UPLOAD('PUT', `/home/${this.state.editId}`, data, files, 'image', true)
            .then(res => {
                // console.log('final edit wala main data >>> ',res.iam);
                    this.setState((prev)=> ({
                        // editId: res._id,
                        tableData: {...res, currently: res.image, image: '',preview:''},
                        editComponent: true
                    }))
            })
            .catch(err => {
                notify.handleError(err);
            })
            .finally(() => {
                this.setState(({
                        // isSubmitting: false,
                    }), () => {
                        this.hasOldData();
                        notify.showSuccess('Home updated successfully');
                    }
                )
            })
    }


    render() {
        return (
            <>
                {this.state.editComponent
                    ?
                    <>
                        {/*{console.log('create home compo')}*/}
                        <HomeForm title='Update Home'
                                  breadcumb={['Home', 'Update']}
                                  editComponent={true}
                            // btnTitle={this.props.btnTitle}
                            // btnTo={this.props.btnTo}
                                  submitCallback={this.editFunction}
                                  tableData={this.state.tableData}
                                  isLoading={this.state.isLoading}/>
                    </>
                    :
                    <HomeForm title={this.props.title}
                              breadcumb={this.props.breadcumb}
                              editComponent={false}
                        // btnTitle={this.props.btnTitle}
                        // btnTo={this.props.btnTo}
                              submitCallback={this.addFunction}
                              isSubmitting={this.state.isSubmitting}></HomeForm>
                }

            </>
        )
    }

}

