import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
// import {ListTestimonialComponent} from "../../../components/backend/testimonial/list.testimonial.component";
// import {ListSocialComponent} from "../../../components/backend/social/list.social.component";
// import {ListFactComponent} from "../../../components/backend/fact/list.fact.component";
import {ListSkillComponent} from "../../../components/backend/skill/list.skill.component";

export class ListSkillPage extends React.Component{
    constructor(){
        super();

        this.state = {
            test:''
        }
    }

    render(){
        return(
            <>

                <BackendLayout>
                    <ListSkillComponent
                        title="List Skill"
                        routeProps={this.props}
                        breadcumb={['Skill','List']}

                        btnTitle="Create Skill"
                        btnTo="/admin/skill/create">

                    </ListSkillComponent>
                </BackendLayout>

            </>
        )
    }
}
