import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
// import {CreateUserComponent} from "../../../components/backend/user/create.user.component";
import {EditUserComponent} from "../../../components/backend/user/edit.user.component";
import myHttp from "../../../util/http";
import notify from "../../../util/notify";
// import {UserForm} from "../../../components/backend/user/form.user.component";
// import Loader from 'components/common/loader/loader';

export class EditUserPage extends React.Component {
    constructor() {
        super();
        this.state = {

            isLoading: false,
            user: ''
        }
    }


    componentDidMount() {
        this.userId = this.props.match.params['id'];
        this.setState({
            isLoading: true
        })
        myHttp.GET(`/user/${this.userId}`, true,)
            .then(res => {
                this.setState({
                    user: res.data
                })
            })
            .catch(err => notify.handleError(err))
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }


    render() {
        return (<>
            <BackendLayout>
                <EditUserComponent
                    title={'Edit User'}
                    breadcumb={['User','Edit']}
                    btnTitle={'List User'}
                    btnTo={'/user'}
                    userx={this.state.user}
                    isSubmitting={this.state.isSubmitting}
                    routeProps={this.props}>

                </EditUserComponent>
            </BackendLayout>
        </>)
    }
}
