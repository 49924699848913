import './sidebar.component.css';
import jquery from 'jquery';
import React from 'react';
import {Link} from 'react-router-dom';

const imgUrl = process.env.REACT_APP_IMG_URL;

export default class SidebarBackend extends React.Component {
    constructor() {
        super();

        this.state = ({     // Sample STATE to ignore WARNING in CONSOLE
            test: ''
        })

    }

    componentDidMount() {
        jquery(function ($) {

            $(".sidebar-dropdown > a").click(function () {
                $(".sidebar-submenu").slideUp(200);
                if (
                    $(this)
                        .parent()
                        .hasClass("active")
                ) {
                    $(".sidebar-dropdown").removeClass("active");
                    $(this)
                        .parent()
                        .removeClass("active");
                } else {
                    $(".sidebar-dropdown").removeClass("active");
                    $(this)
                        .next(".sidebar-submenu")
                        .slideDown(200);
                    $(this)
                        .parent()
                        .addClass("active");
                }
            });

            $("#close-sidebar").click(function () {
                $(".page-wrapper").removeClass("toggled");
            });
            $("#show-sidebar").click(function () {
                $(".page-wrapper").addClass("toggled");
            });


        });
    }

    render() {
        return (

            <>
                <div className="page-wrapper chiller-theme toggled">

                    <nav id="sidebar" className="sidebar-wrapper">
                        <div className="sidebar-content">
                            <div className="sidebar-brand">
                                <Link to="/">Sujit's Portfolio</Link>

                            </div>
                            <div className="sidebar-header">
                                <div className="user-pic">
                                    <img className="img-responsive img-rounded"
                                         height="60px"
                                         src={`${imgUrl}/${JSON.parse(localStorage.getItem('user')).avatar}`}
                                         alt="User mero matra ho chitra"></img>
                                </div>
                                <div className="user-info">
                                      <span className="user-name">Welcome
                                        <strong> {JSON.parse(localStorage.getItem('user')).name}</strong>
                                      </span>
                                    <span className="user-role">{JSON.parse(localStorage.getItem('user')).role}</span>
                                    <span className="user-status">
                                        <i className="fa fa-circle"></i>
                                        <span>Online</span>
                                    </span>
                                </div>
                            </div>

                            <div className="sidebar-menu">
                                <ul>

                                    {/*<li className="sidebar-dropdown">*/}
                                    {/*    <Link to="">*/}
                                    {/*        <i className="fa fa-dashboard"></i>*/}
                                    {/*        <span>Dashboard</span>*/}
                                    {/*        <span className="badge badge-pill badge-warning">New</span>*/}
                                    {/*    </Link>*/}
                                    {/*    <div className="sidebar-submenu">*/}
                                    {/*        <ul>*/}
                                    {/*            <li>*/}
                                    {/*                <a href="/">Dashboard 1*/}
                                    {/*                    <span className="badge badge-pill badge-success">Pro</span>*/}
                                    {/*                </a>*/}
                                    {/*            </li>*/}
                                    {/*            <li>*/}
                                    {/*                <a href="/">Dashboard 2</a>*/}
                                    {/*            </li>*/}
                                    {/*            <li>*/}
                                    {/*                <a href="/">Dashboard 3</a>*/}
                                    {/*            </li>*/}
                                    {/*        </ul>*/}
                                    {/*    </div>*/}
                                    {/*</li>*/}

                                    <li className="sidebar-dropdown">
                                        <Link to="#">
                                            <i className="far fa-user"></i>
                                            <span>User</span>
                                        </Link>
                                        <div className="sidebar-submenu">
                                            <ul>
                                                <li>
                                                    <Link to="/user">List</Link>
                                                </li>
                                                <li>
                                                    <Link to="/user/create">Create</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>

                                    <li className="sidebar-dropdown">
                                        <Link to="#">
                                            <i className="far fa-user"></i>
                                            <span>Testimonial</span>
                                        </Link>
                                        <div className="sidebar-submenu">
                                            <ul>
                                                <li>
                                                    <Link to="/admin/testimonial">List</Link>
                                                </li>
                                                <li>
                                                    <Link to="/admin/testimonial/create">Create</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>


                                    <li className="sidebar">
                                        <Link to="/admin/home">
                                            <i className="fa fa-home"></i>
                                            <span>Home</span>
                                        </Link>

                                    </li>


                                    <li className="sidebar-dropdown">
                                        <Link to="#">
                                            <i className="far fa-user"></i>
                                            <span>Social</span>
                                        </Link>
                                        <div className="sidebar-submenu">
                                            <ul>
                                                <li>
                                                    <Link to="/admin/social">List</Link>
                                                </li>
                                                <li>
                                                    <Link to="/admin/social/create">Create</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>

                                    <li className="sidebar">
                                        <Link to="/admin/about">
                                            <i className="fa fa-home"></i>
                                            <span>About</span>
                                        </Link>

                                    </li>


                                    <li className="sidebar-dropdown">
                                        <Link to="#">
                                            <i className="far fa-user"></i>
                                            <span>Fact</span>
                                        </Link>
                                        <div className="sidebar-submenu">
                                            <ul>
                                                <li>
                                                    <Link to="/admin/fact">List</Link>
                                                </li>
                                                <li>
                                                    <Link to="/admin/fact/create">Create</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>


                                    <li className="sidebar-dropdown">
                                        <Link to="#">
                                            <i className="far fa-user"></i>
                                            <span>Skill</span>
                                        </Link>
                                        <div className="sidebar-submenu">
                                            <ul>
                                                <li>
                                                    <Link to="/admin/skill">List</Link>
                                                </li>
                                                <li>
                                                    <Link to="/admin/skill/create">Create</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>


                                    <li className="sidebar-dropdown">
                                        <Link to="#">
                                            <i className="far fa-user"></i>
                                            <span>Interest</span>
                                        </Link>
                                        <div className="sidebar-submenu">
                                            <ul>
                                                <li>
                                                    <Link to="/admin/interest">List</Link>
                                                </li>
                                                <li>
                                                    <Link to="/admin/interest/create">Create</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>


                                    <li className="sidebar">
                                        <Link to="/admin/contact">
                                            <i className="fa fa-home"></i>
                                            <span>Contact</span>
                                        </Link>
                                    </li>


                                    <li className="sidebar-dropdown">
                                        <Link to="#">
                                            <i className="far fa-user"></i>
                                            <span>Service</span>
                                        </Link>
                                        <div className="sidebar-submenu">
                                            <ul>
                                                <li>
                                                    <Link to="/admin/service">List</Link>
                                                </li>
                                                <li>
                                                    <Link to="/admin/service/create">Create</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>


                                    <li className="sidebar-dropdown">
                                        <Link to="#">
                                            <i className="far fa-user"></i>
                                            <span>Category</span>
                                        </Link>
                                        <div className="sidebar-submenu">
                                            <ul>
                                                <li>
                                                    <Link to="/admin/category">List</Link>
                                                </li>
                                                <li>
                                                    <Link to="/admin/category/create">Create</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>




                                    <li className="sidebar-dropdown">
                                        <Link to="#">
                                            <i className="far fa-user"></i>
                                            <span>Portfolio</span>
                                        </Link>
                                        <div className="sidebar-submenu">
                                            <ul>
                                                <li>
                                                    <Link to="/admin/portfolio">List</Link>
                                                </li>
                                                <li>
                                                    <Link to="/admin/portfolio/create">Create</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>

                                    <li className="sidebar">
                                        <Link to="/admin/summary">
                                            <i className="fa fa-home"></i>
                                            <span>Summary</span>
                                        </Link>
                                    </li>


                                    <li className="sidebar-dropdown">
                                        <Link to="#">
                                            <i className="far fa-user"></i>
                                            <span>Education</span>
                                        </Link>
                                        <div className="sidebar-submenu">
                                            <ul>
                                                <li>
                                                    <Link to="/admin/education">List</Link>
                                                </li>
                                                <li>
                                                    <Link to="/admin/education/create">Create</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>


                                    <li className="sidebar-dropdown">
                                        <Link to="#">
                                            <i className="far fa-user"></i>
                                            <span>Training</span>
                                        </Link>
                                        <div className="sidebar-submenu">
                                            <ul>
                                                <li>
                                                    <Link to="/admin/training">List</Link>
                                                </li>
                                                <li>
                                                    <Link to="/admin/training/create">Create</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>

                                    <li className="sidebar-dropdown">
                                        <Link to="#">
                                            <i className="far fa-user"></i>
                                            <span>Experiences/Currently</span>
                                        </Link>
                                        <div className="sidebar-submenu">
                                            <ul>
                                                <li>
                                                    <Link to="/admin/exp_curr">List</Link>
                                                </li>
                                                <li>
                                                    <Link to="/admin/exp_curr/create">Create</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>








                                </ul>
                            </div>

                        </div>

                        <div className="sidebar-footer">
                            <a href="/">
                                <i className="fa fa-bell"></i>
                                <span className="badge badge-pill badge-warning notification">3</span>
                            </a>
                            <a href="/">
                                <i className="fa fa-envelope"></i>
                                <span className="badge badge-pill badge-success notification">7</span>
                            </a>
                            <a href="/">
                                <i className="fa fa-cog"></i>
                                <span className="badge-sonar"></span>
                            </a>
                            <Link to="/logout">
                                <i className="fa fa-power-off"></i>
                            </Link>
                        </div>
                    </nav>


                </div>

            </>
        )
    }
}


