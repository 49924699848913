import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";

import {EditFactComponent} from "../../../components/backend/fact/edit.fact.component";

export class EditFactPage extends React.Component {




    render() {
        return (<>
            <BackendLayout>
                <EditFactComponent
                    title={'Edit Fact'}
                    breadcumb={['Fact','Edit']}
                    btnTitle={'List Fact'}
                    btnTo={'/admin/Fact'}
                    routeProps={this.props}>

                </EditFactComponent>
            </BackendLayout>
        </>)
    }
}
