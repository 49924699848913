import React from 'react';
import notify from "../../../util/notify";
import myHttp from "../../../util/http";
import Loader from "../../common/loader/loader";
import {TrainingForm} from "./form.training.component";


export class EditTrainingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
            isLoading: false,
            tableData: ''
        }
    }

    componentDidMount() {
        this.editId = this.props.routeProps.match.params['id'];
        this.setState({
            isLoading: true
        })
        myHttp.GET(`/training/${this.editId}`, true)
            .then(res => {
                this.setState({
                    // tableData: {...res.data}
                    tableData: res.data
                })
            })
            .catch(err => notify.handleError(err))
            .finally(() => {
                this.setState({
                    isLoading: false,
                    isSubmitting:false
                })
            })
    }


    editFunction = (data, files) => {
        this.setState({
            isSubmitting: true,
            isLoading:true

        })
        // myHttp.UPLOAD('PUT', `/interest/${this.editId}`, data, files, 'image', true)
        myHttp.PUT(`/training/${this.editId}`, data,true)
            .then(res => {
                // this.props.routeProps.history.push('/admin/social');
                notify.showSuccess('Training updated successfully');
            })
            .catch(err => {
                notify.handleError(err);
            })
            .finally(() => {
                this.componentDidMount();

                // this.setState({
                //     isSubmitting: false,
                //     isLoading:false
                // })
            })
    }


    render() {
        let content = this.state.isLoading
            ? <>
               <Loader/>


            </>
            : <>
                {/*difference ko lagi khi lageko natra render hudaina ani load hudaina data haru form ma edit garda*/}
                <TrainingForm title={this.props.title}
                          breadcumb={this.props.breadcumb}
                          btnTitle={this.props.btnTitle}
                          btnTo={this.props.btnTo}
                          submitCallback={this.editFunction}
                          tableData={this.state.tableData}
                          isLoading={this.state.isLoading}/>

            </>
        return (content)
    }

}

