
import React from 'react';
import './skill.dark.component.css';
import myHttp from "../../../../util/http";
import SkillBar from "react-skillbars";
import { TinyLoader } from 'components/UI/TinyLoader';

export class SkillDarkComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            skills: [],
            isLoading: false
        }

    }



    componentDidMount() {


        myHttp.GET('/skill/active')
            .then(res => {
                // console.log('skill info >> ',res.data);
                this.setState({
                    skills: res.data,
                    isLoading: false
                })
            })
            .catch(err => {
                // console.log(err);
            })
    }



    render() {

        const colors = {
            "bar": "rgb(8 119 1)",
            "title": {
                "bar": "#1d6290",
                "title": {
                    "background": "#2980b9",
                    "text": "red",

                }
            }
        }

        let content = this.state.isLoading
            ? <TinyLoader msg="Loading..." />
            : <SkillBar labelWidth={250} skills={this.state.skills} colors={colors} animationDelay={1500} animationDuration={6000} height={'5vh'} />

        return (
            <>
                {/*!-- ======= Skills  ======= --*/}
                <div className="skills container">

                    <div className="section-title">
                        <h2>Skills</h2>
                    </div>

                    <div className="row skills-content">
                        <div className="col-lg-12">
                            <div data-aos="zoom-in" >
                                {content}
                            </div>
                        </div>
                    </div >




                </div>
            </>
        )
    }
}






//
//
//
// import React from 'react';
// import './skill.dark.component.css';
// import myHttp from "../../../../util/http";
// import SkillBar from "react-skillbars";
//
// export class SkillDarkComponent extends React.Component{
//     constructor(props) {
//         super(props);
//         this.state = {
//             skills: []
//         }
//
//     }
//
//
//
//     componentDidMount() {
//         myHttp.GET('/skill/active')
//             .then(res => {
//                 this.setState({
//                     skills: res.data,
//                 })
//             })
//             .catch(err => {
//                 // console.log(err);
//             })
//     }
//
//
//
//     render() {
//         const skills = [
//             {type: "Java", level: 85},
//             {type: "Javascript", level: 75},
//         ];
//
//         return (
//             <>
//                 {/*!-- ======= Skills  ======= --*/}
//                 <div class="skills container">
//
//                     <div class="section-title">
//                         <h2>Skills</h2>
//                     </div>
//
//                     <div class="row skills-content">
//
//                         <div class="col-lg-6">
//
//                             {
//                                 this.state.skills.filter(
//                                     (item,index) => {
//                                         if(index%2 === 0){
//                                             return true;
//                                         }
//                                     }
//                                 ).map(
//                                     (it,index) => (
//                                         <div key={index} className="progress">
//
//                                             <span className="skill">{it.subject} <i className="val">{it.percentage}%</i></span>
//                                             <div className="progress-bar-wrap">
//                                                 <div className="progress-bar" role="progressbar" aria-valuenow="100"
//                                                      aria-valuemin="0" aria-valuemax="100"></div>
//                                             </div>
//                                         </div>
//                                     )
//                                 )
//                             }
//
//                         </div>
//
//                         <div class="col-lg-6">
//
//                             {
//                                 this.state.skills.filter(
//                                     (item,index) => {
//                                         if(index%2 === 1){
//                                             return true;
//                                         }
//                                     }
//                                 ).map(
//                                     (it,index) => (
//                                         <div key={index} className="progress">
//                                             <span className="skill">{it.subject} <i className="val">{it.percentage}%</i></span>
//                                             <div className="progress-bar-wrap">
//                                                 <div className="progress-bar" role="progressbar" aria-valuenow="100"
//                                                      aria-valuemin="0" aria-valuemax="100"></div>
//                                             </div>
//                                         </div>
//                                     )
//                                 )
//                             }
//
//                         </div>
//
//                     </div>
//
//                 </div>
//             </>
//         )
//     }
// }