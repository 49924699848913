import React from 'react';
import './reset.component.css';
import {Link} from 'react-router-dom';
import http from './../../../util/http';
import notify from './../../../util/notify';

const DefaultForm = {
    password: '',
    cpassword: ''
}

export class ResetPassword extends React.Component {
    constructor() {
        super();
        this.state = {
            data: {
                ...DefaultForm
            },
            error: {
                ...DefaultForm
            },
            pure: {
                password: true,
                cpassword: true,
            },
            id: null,
            isSubmitting: false,
            isValidForm: false
        }
    }


    handleChange = (e) => {
        const {name, value} = e.target;
        this.setState(preState => ({
            data: {
                ...preState.data,
                [name]: value
            },
            pure: {
                ...preState.pure,
                [name]: false
            }
        }), () => {
            this.validateForm(name);
        });

    }

    componentDidMount() {
        this.setState(preState => ({
            ...preState,
            id: this.props.match.params.id
        }),()=>{
            // console.log(this.state.id);
        })

    }

    validateForm(fieldName) {

        let errMsg;
        switch (fieldName) {
            case 'password':
                errMsg = this.state.data[fieldName]
                    ? this.state.data[fieldName].length > 6
                        ? this.state.data[fieldName] === this.state.data.cpassword
                            ? this.verifiedPassword()
                            : 'Password don\'t matched'
                        : 'Weak Password'
                    : 'Password is required'
                break

            case 'cpassword':
                fieldName = 'password';
                errMsg = this.state.data[fieldName]
                    ? this.state.data.cpassword.length > 6
                        ? this.state.data[fieldName] === this.state.data.cpassword
                            ? this.verifiedPassword()
                            : 'Password don\'t matched'
                        : 'Weak Password'
                    : 'Password is required'
                break

            default:
                break;
        }
        this.setState(preState => ({
            error: {
                ...preState.error,
                [fieldName]: errMsg
            }
        }), () => {
            const {error, pure} = this.state;
            let err = Object.values(error).filter(item => item);
            let dirty = Object.values(pure).filter(item => item);
            // console.log(err);
            this.setState({
                isValidForm: err.length === 0 && dirty.length === 0
            })
        })
    }

    verifiedPassword() {
        this.setState(preState => ({
            error: {
                ...preState.error,
                password: '',
                cpassword: '',
            }
        }))
    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState({isSubmitting: true});
        http.POST('/auth/reset-password/' + this.state.id, this.state.data)
            .then(data => {
                notify.showSuccess("Password Reseted Successfully");
                this.props.history.push('/login');
            })
            .catch(err => {
                notify.handleError(err);
                this.setState(prev => ({
                    isSubmitting: false,
                    data: {
                        ...prev.data,
                        password: '',
                        cpassword: ''
                    },
                    error: {
                        ...prev.error,
                        password: '',
                        cpassword: ''
                    },
                    isValidForm: false,
                    pure: {

                        password: true,
                        cpassword: true,
                    },
                }))
            })

    }

    render() {
        let btn = this.state.isSubmitting
            ? <button disabled type="button" name="button"
                      className="btn login_btn">Resetting Now...</button>
            : <button disabled={!this.state.isValidForm} type="submit" name="button"
                      className="btn login_btn">Change Password</button>
        return <div className="container h-100">
            <div className="d-flex justify-content-center h-100">
                <div className="user_card">
                    <div className="d-flex justify-content-center">
                        <div className="brand_logo_container">
                            <img height="100px"
                                 src="https://upload.wikimedia.org/wikipedia/commons/3/33/Vanamo_Logo.png"
                                 className="brand_logo" alt="Logo"/>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center form_container">
                        <form onSubmit={this.handleSubmit} noValidate>


                            <div className="input-group mb-1">
                                <div className="input-group-append">
                                    <span className="input-group-text"><i className="fas fa-key"></i></span>
                                </div>
                                <input value={this.state.data.password} type="password" name="password"
                                       className="form-control input_pass"
                                       placeholder="Password" onChange={this.handleChange}/>
                            </div>
                            <p className="error">{this.state.error.password}</p>

                            <div className="input-group mb-1">
                                <div className="input-group-append">
                                    <span className="input-group-text"><i className="fas fa-key"></i></span>
                                </div>
                                <input value={this.state.data.cpassword} type="password" name="cpassword"
                                       className="form-control input_pass"
                                       placeholder="Confirm Password" onChange={this.handleChange}/>
                            </div>


                            <div className="d-flex justify-content-center mt-3 login_container">
                                {btn}
                            </div>
                        </form>
                    </div>

                    <div className="mt-4">
                        <div className="d-flex justify-content-center links">
                            Already Registered? <Link to="/login" className="ml-2">Login Now</Link>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    }
}