import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
import {CreateFactComponent} from "../../../components/backend/fact/create.fact.component";

export class CreateFactPage extends React.Component{
    constructor(){
        super();

        this.state = {
            test:''
        }
    }

    componentDidMount() {

    }

    render(){
        return(
            <>
                <BackendLayout>
                    <CreateFactComponent
                        title="Create Fact"
                        routeProps ={this.props}
                        breadcumb={['Fact','Create']}
                        btnTitle="List Fact"
                        btnTo="/admin/fact">
                    </CreateFactComponent>
                </BackendLayout>
            </>
        )
    }
}
