import React from 'react';
import ReactDOM from 'react-dom';

import Card from './Card';
// import Button from './Button';
import classes from './MyModal.module.css';

// const imageUrl = process.env.REACT_APP_IMG_URL;

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.handleClose} />;
};

const ImageOverlay = (props) => {
    return (
        <Card className={classes.modal}>
            {/*<header className={classes.header}>*/}
            {/*  <h2>{props.title}</h2>*/}
            {/*</header>*/}
            <div className={classes.content}>
                <p>{props.message}</p>
                <p>
                    <img alt="Pic" height="70%" width="100%" src={`${props.imgUrl}`} />
                </p>
            </div>
            {/*<footer className={classes.actions}>*/}
            {/*  <Button onClick={props.handleClose}>Okay</Button>*/}
            {/*</footer>*/}
        </Card>
    );
};

const ImageModal = (props) => {
    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <Backdrop handleClose={props.handleClose} />,
                document.getElementById('backdrop-root')
            )}
            {ReactDOM.createPortal(
                <ImageOverlay

                    imgUrl={props.imgUrl}
                    handleClose={props.handleClose}
                />,
                document.getElementById('overlay-root')
            )}
        </React.Fragment>
    );
};


 // CONTENT MODAL
const ContentOverlay = (props) => {
    return (
        <Card className={classes.modal}>
            {/*<header className={classes.header}>*/}
            {/*  <h2>{props.title}</h2>*/}
            {/*</header>*/}
            <div className={classes.content}>
                <p>{props.message}</p>
                <p>
                    <img alt="Pic" height="70%" width="100%" src={`${props.imgUrl}`} />
                </p>
            </div>
            {/*<footer className={classes.actions}>*/}
            {/*  <Button onClick={props.handleClose}>Okay</Button>*/}
            {/*</footer>*/}
        </Card>
    );
};

const ContentModal = (props) => {
    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <Backdrop handleClose={props.handleClose} />,
                document.getElementById('backdrop-root')
            )}
            {ReactDOM.createPortal(
                <ContentOverlay
                    title={props.title}
                    message={props.message}
                    imgUrl={props.imgUrl}
                    handleClose={props.handleClose}
                />,
                document.getElementById('overlay-root')
            )}
        </React.Fragment>
    );
};
export  {ImageModal,ContentModal};
