import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
// import {CreateTestimonialComponent} from "../../../components/backend/testimonial/create.testimonial.component";
import {CreateSocialComponent} from "../../../components/backend/social/create.social.component";

export class CreateSocialPage extends React.Component{
    constructor(){
        super();

        this.state = {
            test:''
        }
    }

    componentDidMount() {

    }

    render(){
        return(
            <>
                <BackendLayout>
                    <CreateSocialComponent
                        title="Create Social"
                        routeProps ={this.props}
                        breadcumb={['Social','Create']}
                        btnTitle="List Social"
                        btnTo="/admin/social">
                    </CreateSocialComponent>
                </BackendLayout>
            </>
        )
    }
}
