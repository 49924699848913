import React from 'react';
import './interest.dark.component.css';
import myHttp from "../../../../util/http";
import { TinyLoader } from 'components/UI/TinyLoader';

const imgUrl = process.env.REACT_APP_IMG_URL;

export class InterestDarkComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            interests: [],
            isLoading:true
        }
    }

    componentDidMount() {


        myHttp.GET('/interest/active')
            .then(response => {
                this.setState({
                    interests: response.data,
                    isLoading:false
                })
            })
            .catch(err => {

            })
    }


    render() {
        let content = this.state.isLoading
        ? <TinyLoader msg="Loading..."/>
        : <div className="row">

        {
            this.state.interests.map((interest, index) => (

                <div key={"interest_"+index} data-aos="flip-up"  className="col-lg-3 col-md-4">
                    <div className="icon-box">
                        {
                            interest.image_status ==='Display' && interest.image
                            ?<img style={{paddingRight:'5px'}} height="32px" src={`${imgUrl}/${interest.image}`} alt="interest"/>

                            :<i className={interest.logo} style={{color: `${interest.color}`}}></i>
                        }
                       
                        
                        <h3>{interest.subject}</h3>
                    </div>
                </div>

                )
            )
        }




    </div>
        return (
            <>
                {/*-- ======= Interests ======= --*/}
                <div className="interests container">

                    <div className="section-title">
                        <h2>Interests</h2>
                    </div>

                    {content}

                </div>
            </>
        )
    }
}