import React from "react";
import {BackendLayout} from "../../../layouts/backend.layout";
// import {CreateSkillComponent} from "../../../components/backend/skill/create.skill.component";
import {CreatePortfolioComponent} from "../../../components/backend/portfolio/create.portfolio.component";

export class CreatePortfolioPage extends React.Component{
    // constructor(){
    //     super();
    //
    //     this.state = {
    //         test:''
    //     }
    // }

    componentDidMount() {

    }

    render(){
        return(
            <>
                <BackendLayout>
                    <CreatePortfolioComponent
                        title="Create Portfolio"
                        routeProps ={this.props}
                        breadcumb={['Portfolio','Create']}
                        btnTitle="List Portfolio"
                        btnTo="/admin/portfolio">
                    </CreatePortfolioComponent>
                </BackendLayout>
            </>
        )
    }
}
