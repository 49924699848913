import React, { Suspense } from 'react';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import { NotFound } from './../common/notfound/notfound.component';
import { Login } from './../auth/login2/login.component';
import { Register } from './../auth/register/register.component';

import { DashboardPage } from '../../pages/backend/dashboard/dashboard.page';
import { ForgotPassword } from './../auth/forgotPassword/forgot.component';
import { ResetPassword } from './../auth/resetPassword/reset.component';



import Home from '../../pages/frontend_dark/home.frontend';


// import { CreateUserPage } from "pages/backend/user/createuser.page";
import { ListUserPage } from "../../pages/backend/user/listuser.page";
import { EditUserPage } from "../../pages/backend/user/edituser.page";

import { CreateTestimonialPage } from "pages/backend/testimonial/createTestimonial.page";
import { ListTestimonialPage } from "pages/backend/testimonial/listTestimonial.page";
import { EditTestimonialPage } from "pages/backend/testimonial/editTestimonial.page";

// IMPORT Frontend -DARK

import AboutDarkPage from "../../pages/frontend_dark/about.dark.page";
import ResumeDarkPage from "../../pages/frontend_dark/resume.dark.page";
import ServiceDarkPage from "../../pages/frontend_dark/service.dark.page";
import PortfolioDarkPage from "../../pages/frontend_dark/portfolio.dark.page";
import ContactDarkPage from "../../pages/frontend_dark/contact.dark.page";
import { HeaderDarkComponent } from "../header/frontend/dark/header.dark.component";

import { CreateHomePage } from "../../pages/backend/home/createHome.page";

import { CreateSocialPage } from "../../pages/backend/social/createSocial.page";
import { EditSocialPage } from "../../pages/backend/social/editSocial.page";
import { ListSocialPage } from "../../pages/backend/social/listSocial.page";

import { CreateAboutPage } from "../../pages/backend/about/createAbout.page";

import { CreateFactPage } from "../../pages/backend/fact/createFact.page";
import { ListSkillPage } from "../../pages/backend/skill/listSkill.page";
import { EditSkillPage } from "../../pages/backend/skill/editSkill.page";

// import {CreateFactPage} from "../../pages/backend/fact/createFact.page";
import { ListFactPage } from "../../pages/backend/fact/listFact.page";
import { EditFactPage } from "../../pages/backend/fact/editFact.page";
import { CreateSkillPage } from "../../pages/backend/skill/createSkill.page";

import { CreateInterestPage } from "../../pages/backend/interest/createInterest.page";
import { ListInterestPage } from "../../pages/backend/interest/listInterest.page";
import { EditInterestPage } from "../../pages/backend/interest/editInterest.page";

import { CreateContactPage } from "../../pages/backend/contact/createContact.page";

import { CreateServicePage } from "../../pages/backend/service/createService.page";
import { ListServicePage } from "../../pages/backend/service/listService.page";
import { EditServicePage } from "../../pages/backend/service/editService.page";

import { CreateCategoryPage } from "../../pages/backend/category/createCategory.page";
import { ListCategoryPage } from "../../pages/backend/category/listCategory.page";
import { EditCategoryPage } from "../../pages/backend/category/editCategory.page";

import { CreateSummaryPage } from "../../pages/backend/resume/summary/createSummary.page";

import { CreateEducationPage } from "../../pages/backend/resume/education/createEducation.page";
import { ListEducationPage } from "../../pages/backend/resume/education/listEducation.page";
import { EditEducationPage } from "../../pages/backend/resume/education/editEducation.page";

import { CreateTrainingPage } from "../../pages/backend/resume/training/createTraining.page";
import { ListTrainingPage } from "../../pages/backend/resume/training/listTraining.page";
import { EditTrainingPage } from "../../pages/backend/resume/training/editTraining.page";
import { CreateExpPage } from "../../pages/backend/resume/exp_curr/createExp.page";
import { ListExpPage } from "../../pages/backend/resume/exp_curr/listExp.page";
import { EditExpPage } from "../../pages/backend/resume/exp_curr/editExp.page";
import { DetailsDarkComponent } from "../frontend_dark/portfolio/details.dark.component";
import { CreatePortfolioPage } from "../../pages/backend/portfolio/createPortfolio.page";
import { ListPortfolioPage } from "../../pages/backend/portfolio/listPortfolio.page";
import { EditPortfolioPage } from "../../pages/backend/portfolio/editPortfolio.page";
const CreateUserPage = React.lazy(() => import('./../../pages/backend/user/createuser.page'));
var jwt = require('jsonwebtoken');

// import {ListHomePage} from "../../pages/backend/home/listHome.page";
// import {EditHomePage} from "../../pages/backend/home/editHome.page";


// ************************************************* //

const AuthRoute = ({ component: Component, ...rest }) => { // Auth user will redirect to /dashboard if access to auth related route otherwise no change
    return <Route
        {...rest} render={(routeProps) => (

            localStorage.getItem('token')
                ? <Redirect to="/dashboard" />
                : <Component {...routeProps} />

        )}
    ></Route>
}

export const isValidToken = ({ }) => {
    let token = localStorage.getItem('token');
    jwt.verify(token, 'SnBFilms', function (err, decoded) {

        if (err) {
            return false;
        } else {
            return true;
        }
    })
}


const BackendRoute = ({ component: Component, ...rest }) => {  // Only for ALL Backend wala route
    // console.log('mathi ko component >> ',Component);

    let token = localStorage.getItem('token');

    let isValidToken = jwt.verify(token, 'SnBFilms', function (err, decoded) {
        if (err) {
            return false;
        } else {
            return true;
        }
    })


    if (isValidToken) {

    } else {
        return <Route
            {...rest} render={(routeProps) => (
                <Redirect to="/" />
            )}
        ></Route>
    }


    return <Route
        {...rest} render={(routeProps) => (
            localStorage.getItem('token')
                ? <Component {...routeProps} />
                : <Redirect to="/login" />
        )}
    ></Route>
}

// const BackendRoute = ({component: Component, ...rest}) => {  // Only for ALL Backend wala route
//     return <Route
//         {...rest} render={(routeProps) => (
//         localStorage.getItem('token')
//             ? <Component {...routeProps}/>
//             : <Redirect to="/login"/>
//     )}
//     ></Route>
// }


// const BackendRoute = ({ component: Component, ...rest }) => {  // Only for ALL Backend wala route
//     const token = localStorage.getItem('token');
//     if (token) {
//         // console.log('token is  >> ', token);

//         // var decodedToken = jwt.decode(token, { complete: true });
//         // var dateNow = new Date();

//         // console.log("decode token >> ",decodedToken);
//         // console.log("token exp >> ",decodedToken.exp);

//         // if (decodedToken.exp < dateNow.getTime()) {
//         //     return <Route
//         //         {...rest} render={(routeProps) => (
//         //             <Redirect to="/login" />
//         //         )}
//         //     ></Route>
//         // } else {
//         //     return <Route
//         //         {...rest} render={(routeProps) => (
//         //             <Component {...routeProps} />
//         //         )}
//         //     ></Route>
//         // }


//         // return <Route
//         //             {...rest} render={(routeProps) => (
//         //                 <Redirect to="/" />
//         //             )}
//         //         ></Route>

//         jwt.verify(token, 'SnBFilms', function (err, decoded,Component,...rest) {
//             console.log('inside verify');
//             return <Route
//                 {...rest} render={(routeProps) => (
//                     <Component {...routeProps} />
//                 )}
//             ></Route>
//             console.log('err verify123 >> ',err);

//             // return <Route
//             //         {...rest} render={(routeProps) => (
//             //             <Redirect to="/" />
//             //         )}
//             //     ></Route>

//             // if (err.message==='invalid signature') {
//             //     return <Route
//             //         {...rest} render={(routeProps) => (
//             //             <Redirect to="/" />
//             //         )}
//             //     ></Route>

//             // }else{
//             //     return <Route
//             //     {...rest} render={(routeProps) => (

//             //         <Component {...routeProps} />

//             //     )}
//             // ></Route>
//             // }

//         });

//     } else {  // if TOKEN do not exists
//         return <Route
//         {...rest} render={(routeProps) => (
//             <Redirect to="/else-notoken" />
//         )}
//     ></Route>
//     }


// }

const PublicRoute = ({ component: Component, ...rest }) => {  // Only for ALL Backend wala route
    return <Route
        {...rest} render={(routeProps) => (
            <>
                <HeaderDarkComponent header={true}></HeaderDarkComponent>
                <span><Component {...routeProps} /></span>
                <br />
                <h1>&nbsp;</h1>
            </>


        )}
    ></Route>
}

export const Routing = () => {
    return <div>
        <Router>

            <Suspense fallback={<p>Loading...</p>}>
                <Switch>

                    {/**********************************************/}
                    {/***********   FRONTEND     ********************/}
                    {/**********************************************/}


                    <Route
                        path="/"
                        component={Home}
                        exact
                    />

                    <PublicRoute
                        path="/about"
                        component={AboutDarkPage}
                        exact
                    />

                    <PublicRoute
                        path="/resume"
                        component={ResumeDarkPage}
                        exact
                    />

                    <PublicRoute
                        path="/services"
                        component={ServiceDarkPage}
                        exact
                    />

                    <PublicRoute
                        path="/portfolio"
                        component={PortfolioDarkPage}
                        exact
                    />


                    <PublicRoute
                        path="/portfolio/:slug"
                        component={DetailsDarkComponent}
                        exact
                    />

                    <PublicRoute
                        path="/contact"
                        component={ContactDarkPage}
                        exact
                    />

                    {/*     AUTH    */}

                    <AuthRoute
                        path="/login"
                        component={Login}
                        exact
                    />

                    {/* <AuthRoute
                    exact
                    path="/register"
                    component={Register}
                /> */}

                    <AuthRoute
                        exact
                        path="/forgot-password"
                        component={ForgotPassword}
                    />

                    <AuthRoute
                        exact
                        path="/reset-password/:id"
                        component={ResetPassword}
                    />

                    <BackendRoute
                        path="/dashboard"
                        component={ListUserPage}
                        exact
                    />

                    {/**********************************************/}
                    {/***********   BACKEND     ********************/}
                    {/**********************************************/}


                    {/********     DASHBOARD    ********/}

                    <BackendRoute
                        path="/dashboard"
                        component={DashboardPage}
                        exact
                    />


                    {/********     USER    ********/}

                    <BackendRoute
                        path="/user/create"
                        component={CreateUserPage}
                        exact
                    />

                    <Route
                        path="/user"
                        component={ListUserPage}
                        exact
                    />

                    <BackendRoute
                        path="/user/:id/edit"
                        component={EditUserPage}
                        exact
                    />


                    {/********     Testimonial    ********/}

                    <BackendRoute
                        path="/admin/testimonial/create"
                        component={CreateTestimonialPage}
                        exact
                    />

                    <BackendRoute
                        path="/admin/testimonial"
                        component={ListTestimonialPage}
                        exact
                    />

                    <BackendRoute
                        path="/admin/testimonial/:id/edit"
                        component={EditTestimonialPage}
                        exact
                    />


                    {/********     Home    ********/}

                    <BackendRoute
                        path="/admin/home"
                        component={CreateHomePage}
                        exact
                    />


                    {/********     Social    ********/}

                    <BackendRoute
                        path="/admin/social/create"
                        component={CreateSocialPage}
                        exact
                    />

                    <BackendRoute
                        path="/admin/social"
                        component={ListSocialPage}
                        exact
                    />

                    <BackendRoute
                        path="/admin/social/:id/edit"
                        component={EditSocialPage}
                        exact
                    />


                    {/********     About    ********/}

                    <BackendRoute
                        path="/admin/about"
                        component={CreateAboutPage}
                        exact
                    />


                    {/********     Fact    ********/}

                    <BackendRoute
                        path="/admin/fact/create"
                        component={CreateFactPage}
                        exact
                    />


                    <BackendRoute
                        path="/admin/fact"
                        component={ListFactPage}
                        exact
                    />

                    <BackendRoute
                        path="/admin/fact/:id/edit"
                        component={EditFactPage}
                        exact
                    />


                    {/********     Skill    ********/}

                    <BackendRoute
                        path="/admin/skill/create"
                        component={CreateSkillPage}
                        exact
                    />


                    <BackendRoute
                        path="/admin/skill"
                        component={ListSkillPage}
                        exact
                    />

                    <BackendRoute
                        path="/admin/skill/:id/edit"
                        component={EditSkillPage}
                        exact
                    />




                    {/********     Portfolio    ********/}

                    <BackendRoute
                        path="/admin/portfolio/create"
                        component={CreatePortfolioPage}
                        exact
                    />


                    <BackendRoute
                        path="/admin/portfolio"
                        component={ListPortfolioPage}
                        exact
                    />

                    <BackendRoute
                        path="/admin/portfolio/:id/edit"
                        component={EditPortfolioPage}
                        exact
                    />


                    {/********     Interest    ********/}

                    <BackendRoute
                        path="/admin/interest/create"
                        component={CreateInterestPage}
                        exact
                    />


                    <BackendRoute
                        path="/admin/interest"
                        component={ListInterestPage}
                        exact
                    />

                    <BackendRoute
                        path="/admin/interest/:id/edit"
                        component={EditInterestPage}
                        exact
                    />



                    {/********     Contact    ********/}

                    <BackendRoute
                        path="/admin/contact"
                        component={CreateContactPage}
                        exact
                    />


                    {/********     Service    ********/}

                    <BackendRoute
                        path="/admin/service/create"
                        component={CreateServicePage}
                        exact
                    />


                    <BackendRoute
                        path="/admin/service"
                        component={ListServicePage}
                        exact
                    />

                    <BackendRoute
                        path="/admin/service/:id/edit"
                        component={EditServicePage}
                        exact
                    />


                    {/********     Category    ********/}

                    <BackendRoute
                        path="/admin/category/create"
                        component={CreateCategoryPage}
                        exact
                    />


                    <BackendRoute
                        path="/admin/category"
                        component={ListCategoryPage}
                        exact
                    />

                    <BackendRoute
                        path="/admin/category/:id/edit"
                        component={EditCategoryPage}
                        exact
                    />


                    {/********     Summary    ********/}

                    <BackendRoute
                        path="/admin/summary"
                        component={CreateSummaryPage}
                        exact
                    />

                    {/********     Education    ********/}

                    <BackendRoute
                        path="/admin/education/create"
                        component={CreateEducationPage}
                        exact
                    />


                    <BackendRoute
                        path="/admin/education"
                        component={ListEducationPage}
                        exact
                    />

                    <BackendRoute
                        path="/admin/education/:id/edit"
                        component={EditEducationPage}
                        exact
                    />


                    {/********     Training    ********/}

                    <BackendRoute
                        path="/admin/training/create"
                        component={CreateTrainingPage}
                        exact
                    />


                    <BackendRoute
                        path="/admin/training"
                        component={ListTrainingPage}
                        exact
                    />

                    <BackendRoute
                        path="/admin/training/:id/edit"
                        component={EditTrainingPage}
                        exact
                    />

                    {/********     Experiences - Currently    ********/}

                    <BackendRoute
                        path="/admin/exp_curr/create"
                        component={CreateExpPage}
                        exact
                    />


                    <BackendRoute
                        path="/admin/exp_curr"
                        component={ListExpPage}
                        exact
                    />

                    <BackendRoute
                        path="/admin/exp_curr/:id/edit"
                        component={EditExpPage}
                        exact
                    />









                    {/********     404 ERROR    ********/}

                    <Route
                        component={NotFound}
                    />

                </Switch>
            </Suspense>


        </Router>
    </div>
}

