import React from "react";
import {BackendLayout} from "../../../../layouts/backend.layout";
// import {ListTrainingComponent} from "../../../../components/backend/training/list.training.component";
import {ListExpComponent} from "../../../../components/backend/exp_curr/list.exp.component";
export class ListExpPage extends React.Component{


    render(){
        return(
            <>

                <BackendLayout>
                    <ListExpComponent
                        title="List Experiences/Currently"
                        routeProps={this.props}
                        breadcumb={['Experiences/Currently','List']}

                        btnTitle="Create Experiences/Currently"
                        btnTo="/admin/exp/create">

                    </ListExpComponent>
                </BackendLayout>

            </>
        )
    }
}
