import React, {useEffect, useState} from "react";

import {Link} from 'react-router-dom';

import Loader from 'components/common/loader/loader';

import {Formik, Form, Field, ErrorMessage} from 'formik'

import './form.training.component.css';

import {object, string, mixed, number} from 'yup';
import {Submitting} from "../../UI/Submitting";
import {Input} from "antd";

const imgUrl = process.env.REACT_APP_IMG_URL;


function TrainingForm(props) {

    const [formValues, setFormValues] = useState(null);
    const [editForm, setEditForm] = useState(false);
    const [isNewImage, setIsNewImage] = useState(false);

    const validate = object({
        course: string()
            .required('Course field is required')
            .max(60, 'Must be 60 characters or less')
            .min(3, 'Must be 3 characters or more'),

        year: string()
            .max(60, 'Must be 60 characters or less')
            .min(3, 'Must be 3 characters or more'),

        institute: string()
            .max(90, 'Must be 90 characters or less')
            .min(3, 'Must be 3 characters or more'),

        message: string("Must be string")
            .max(200, 'Must be 200 characters or less')
            .min(3, 'Must be 3 characters or more'),

        rank: number('Must be numeric value')
            .max(100, 'Must not exceed 100')
            .min(1, 'Must be 1 or more'),

        duration: string()
            .required('Period of training is required')
            .max(100, 'Must not exceed 100')
            .min(1, 'Must be 1 or more'),



        status: mixed()
            .oneOf(['Active', 'Inactive'])
            .required('Status is required'),
        // image_status: mixed()
        //     .oneOf(['Display', 'Hidden'])
        //     .required('Status is required')

        // image: mixed()
        //     .required('Image is required')
        //     .test("size", "File is too large", (value) => {
        //         console.log('sizeee >> ', value);
        //         return value && value.size <= 1 * 1000 * 1000;
        //     })
        //     .test("type", "Supportive file formats required:.jpeg, .jpg, .png", (value) => {
        //         console.log('vallllllllllllllueee >> ', value)
        //
        //         return value && (
        //             value.type === "image/jpeg" ||
        //             value.type === "image/jpg"
        //         )
        //     })


    })


    const initialValues = {
        program: '',
        year: '',
        rank: '',
        university: '',
        message: '',
        status: '',
    }

    // VALIDATE Only if New Image is Selected then goes for validation
    if (isNewImage) {
        validate.fields.image = mixed()
            .required('Image is required')
            .test("size", "File is too large", (value) => {
                return value && value.size <= 1 * 1000 * 1000;
            })
            .test("type", "Supportive file formats required:.jpeg, .jpg, .png", (value) => {

                return value && (
                    value.type === "image/jpeg" ||
                    value.type === "image/jpg" ||
                    value.type === "image/png"
                )
            })
        validate._nodes.push('image');
    }


    useEffect(() => {
        // console.log('props edit data >> ', props.tableData)
        if (props.tableData) {
            setFormValues(props.tableData);
            setEditForm(true);
        }

    }, [props.tableData])


    return (
        <>

            <div className="breadcrumb d-flex justify-content-between align-items-center">
                <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item"><Link to="#">{props.breadcumb[0]}</Link></li>
                    <li className="breadcrumb-item active">{props.breadcumb[1]}</li>
                    <li className="breadcrumb-item active">{props.breadcumb[2]}</li>
                </ol>
                <h4 className="text-center mytitle">{props.title}</h4>
                <Link to={props.btnTo} className="btn btn-sm btn-success">{props.btnTitle}</Link>
            </div>


            <Formik
                initialValues={formValues || initialValues}
                validationSchema={validate}
                enableReinitialize
                onSubmit={
                    (values, submitProps) => {
                        submitProps.setSubmitting(true);
                        props.submitCallback(values, null);
                        submitProps.resetForm(true);
                        submitProps.setSubmitting(false);
                    }
                }
            >
                {
                    (formikProps, setFieldValue, field) => (
                        <>
                            {/*{console.log('formik props >> ',formikProps)}*/}
                            {


                                props.isLoading
                                    ? <Loader/>
                                    : <Form noValidate>

                                        <div className="form-row form-group">
                                            <div className="col-md-6 mb-12">
                                                <label className="label-color">Course*</label>
                                                <Field type="text"
                                                       className={formikProps.touched.course
                                                           ? formikProps.errors.course
                                                               ? 'form-control is-invalid'
                                                               : 'form-control is-valid'
                                                           : 'form-control'
                                                       }

                                                       id="validationServer01"
                                                       name="course"
                                                       placeholder="Course taken"
                                                       autoComplete="off"
                                                />
                                                <ErrorMessage name="course" component="div"
                                                              className="invalid-feedback"></ErrorMessage>
                                            </div>

                                            <div className="col-md-2 mb-12">
                                                <label className="label-color">Duration*</label>
                                                <Field type="text"
                                                       className={formikProps.touched.duration
                                                           ? formikProps.errors.duration
                                                               ? 'form-control is-invalid'
                                                               : 'form-control is-valid'
                                                           : 'form-control'
                                                       }

                                                       id="validationServer01"
                                                       name="duration"
                                                       placeholder="Duration of training"
                                                       autoComplete="off"
                                                />
                                                <ErrorMessage name="duration" component="div"
                                                              className="invalid-feedback"></ErrorMessage>
                                            </div>


                                            <div className="col-md-2 mb-12">
                                                <label className="label-color">Rank</label>
                                                <Field type="text"
                                                       className={formikProps.touched.rank
                                                           ? formikProps.errors.rank
                                                               ? 'form-control is-invalid'
                                                               : 'form-control is-valid'
                                                           : 'form-control'
                                                       }

                                                       id="validationServer01"
                                                       name="rank"
                                                       placeholder="Rank - in Numbers"
                                                       autoComplete="off"
                                                />
                                                <ErrorMessage name="rank" component="div"
                                                              className="invalid-feedback"></ErrorMessage>
                                            </div>





                                            <div className="col-md-2 mb-12">
                                                <label htmlFor="validationServerUsername">Status*</label>
                                                <div className="form-group">
                                                    <Field as="select" name="status"
                                                           className={formikProps.touched.status
                                                               ? formikProps.errors.status
                                                                   ? 'form-control is-invalid'
                                                                   : 'form-control is-valid'
                                                               : 'form-control'
                                                           }
                                                           required>
                                                        <option value="">Select Status</option>
                                                        <option value="Active">Active</option>
                                                        <option value="Inactive">Inactive</option>
                                                    </Field>
                                                    <ErrorMessage name="status" component="div"
                                                                  className="invalid-feedback"></ErrorMessage>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row form-group">
                                            <div className="col-md-8 mb-12">
                                                <label className="label-color">Institute/Medium</label>
                                                <Field type="text"
                                                       className={formikProps.touched.institute
                                                           ? formikProps.errors.institute
                                                               ? 'form-control is-invalid'
                                                               : 'form-control is-valid'
                                                           : 'form-control'
                                                       }

                                                       id="validationServer01"
                                                       name="institute"
                                                       placeholder="Institute or any medium through which training is done"
                                                       autoComplete="off"
                                                />
                                                <ErrorMessage name="institute" component="div"
                                                              className="invalid-feedback"></ErrorMessage>
                                            </div>


                                            <div className="col-md-4 mb-12">
                                                <label className="label-color">Year</label>
                                                <Field type="text"
                                                       className={formikProps.touched.year
                                                           ? formikProps.errors.year
                                                               ? 'form-control is-invalid'
                                                               : 'form-control is-valid'
                                                           : 'form-control'
                                                       }

                                                       id="validationServer01"
                                                       name="year"
                                                       placeholder="Year or with Range"
                                                       autoComplete="off"
                                                />
                                                <ErrorMessage name="year" component="div"
                                                              className="invalid-feedback"></ErrorMessage>
                                            </div>

                                        </div>

                                    <div className="form-row">
                                        <div className="col-md-12 mb-12">
                                            <label htmlFor="validationServerUsername">Message</label>
                                            <div className="form-group">
                                                <Field as="textarea"
                                                       name="message"
                                                       className={formikProps.touched.message
                                                           ? formikProps.errors.message
                                                               ? 'form-control is-invalid'
                                                               : 'form-control is-valid'
                                                           : 'form-control'
                                                       }
                                                       required>

                                                </Field>
                                                <ErrorMessage name="message" component="div"
                                                              className="invalid-feedback"></ErrorMessage>
                                            </div>
                                        </div>
                                    </div>

                                        <div className="form-group">
                                            {
                                                props.isSubmitting
                                                    ? <Submitting msg="Submitting... Please Wait!!!"/>
                                                    // :<button className="btn btn-primary"
                                                    : <button className="btn btn-primary btn-lg btn-block"
                                                              disabled={formikProps.isValid && formikProps.dirty ? false : true}
                                                              type="submit">Submit
                                                    </button>
                                            }
                                        </div>

                                    </Form>

                            }


                        </>
                    )
                }
            </Formik>
            <br/>


        </>
    )
}

export {TrainingForm}
