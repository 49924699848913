import React from 'react';
import './testimonial.dark.component.css';
import myHttp from "../../../../util/http";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { TinyLoader } from 'components/UI/TinyLoader';
// import Loader from 'components/common/loader/loader';
// import ScrollAnimation from 'react-animate-on-scroll'


const imgURL = process.env.REACT_APP_IMG_URL;

export class TestimonialDarkComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonials: [],
            isLoading: true
        }
    }

    componentDidMount() {


        this.setState({
            isLoading: true
        })
        // let prev = this.props.isLoading;
        myHttp.GET('/testimonial/active')
            .then(response => {
                // console.log('tesim >> ', response.data);
                this.setState({
                    testimonials: response.data,
                    isLoading: false
                })
            })
            .catch(err => {

            })
            .finally(this.setState({
                // isLoading: false
            }))
    }


    render() {
        let content = this.state.isLoading
            ? <TinyLoader msg="Loading..." />
            : <div className="testimonials-slider swiper-container" data-aos="fade-up">
                <div className="swiper-wrapper">
                    {
                        this.state.testimonials.length > 0
                            ? <Carousel
                                showArrows={true}
                                infiniteLoop={true}
                                showThumbs={false}
                                showStatus={false}
                                autoPlay={true}
                                interval={10000}
                                stopOnHover={false}
                                showIndicators={false}
                            >


                                {
                                    this.state.testimonials.map(
                                        (item, index) => (
                                            <div key={'testi_' + index}>
                                                {
                                                    item.image
                                                        ? <img alt="client" src={`${imgURL}/${item.image}`} />
                                                        : null
                                                }

                                                <div className="myCarousel">
                                                    <h3>{item.name}</h3>
                                                    <h4>{item.designation}</h4>
                                                    <p>
                                                        {item.message}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    )
                                }


                            </Carousel>
                            : null
                    }


                </div>
            </div>
        return (
            <>
                {/*-- ======= Testimonials ======= --*/}

                <div className="testimonials container">


                    <div className="section-title">
                        <h2>Testimonials</h2>
                    </div>


                    {content}



                </div>
            </>
        )
    }
}


//
//
//
// import React from 'react';
// import './testimonial.dark.component.css';
// import myHttp from "../../../../util/http";
//
// const imgURL = process.env.REACT_APP_IMG_URL;
//
// export class TestimonialDarkComponent extends React.Component{
//     constructor(props) {
//         super(props);
//         this.state = {
//             testimonials:[],
//         }
//     }
//
//     componentDidMount() {
//         // let prev = this.props.isLoading;
//         myHttp.GET('/testimonial/active')
//             .then(response => {
//                 this.setState({
//                     testimonials: response.data
//                 })
//             })
//             .catch(err => {
//
//             })
//     }
//
//
//     render() {
//         return (
//             <>
//                 {/*-- ======= Testimonials ======= --*/}
//                 <div className="testimonials container">
//
//                     <div className="section-title">
//                         <h2>Testimonials</h2>
//                     </div>
//
//                     <div className="testimonials-slider swiper-container" data-aos="fade-up" data-aos-delay="100">
//                         <div className="swiper-wrapper">
//                             {
//                                 this.state.testimonials.map(
//                                     (item,index) => (
//                                         <div key={`testimonial_`+index} className="swiper-slide">
//                                             <div className="testimonial-item">
//                                                 <p>
//                                                     <i className="bx bxs-quote-alt-left quote-icon-left"></i>
//                                                     {item.message}
//                                                     <i className="bx bxs-quote-alt-right quote-icon-right"></i>
//                                                 </p>
//                                                 <img src={`${imgURL}/${item.image}`}
//                                                      className="testimonial-img" alt=""/>
//                                                 <h3>{item.name}</h3>
//                                                 <h4>{item.designation}</h4>
//                                             </div>
//                                         </div>
//                                     )
//                                 )
//                             }
//
//
//
//
//
//                         </div>
//                         <div className="swiper-pagination"></div>
//                     </div>
//
//                     <div className="owl-carousel testimonials-carousel">
//
//                     </div>
//
//                 </div>
//             </>
//         )
//     }
// }