import React from "react";
import {BackendLayout} from "../../../../layouts/backend.layout";
import {CreateExpComponent} from "../../../../components/backend/exp_curr/create.exp.component";

export class CreateExpPage extends React.Component{

    render(){
        return(
            <>
                <BackendLayout>
                    <CreateExpComponent
                        title="Create Experiences/Currently"
                        routeProps ={this.props}
                        breadcumb={['Resume','Experiences/Currently','Create']}
                        btnTitle="List Experiences/Currently"
                        btnTo="/admin/exp_curr">
                    </CreateExpComponent>
                </BackendLayout>
            </>
        )
    }
}
